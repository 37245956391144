import { Radio, withStyles } from "@material-ui/core";
import React from "react";

const GreenRadio = withStyles({
  root: {
    color: "#C0C9CE",
    "&$checked": {
      color: "#2BB472",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioR(props) {
  return <GreenRadio {...props} />;
}
