import React from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";

export default function SelectList({
  label = "",
  name = "",
  value = "",
  onChange,
  list = [],
  showKey = "name",
  valueKey = "id",
  required = false,
  error = "",
  readOnly = false,
  className = "m-form",
}) {
  return (
    <FormControl
      error={error ? true : false}
      required={required}
      className={"form-group " + className}
      fullWidth
    >
      <InputLabel id={"select-" + name}>{label}</InputLabel>
      <Select
        labelId={"select-" + name}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        label={label}
      >
        {list.map((i, index) => (
          <MenuItem key={index} value={i[valueKey]}>
            {i[showKey]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
