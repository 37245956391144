import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import TabR from "../../../components/design/TabR";
import TabsR from "../../../components/design/TabsR";
import { getRequestHeader, list_to_tree } from "../../../components/helpers";
import { a11yProps } from "../../../components/Tab";
import TabPanel from "../../../components/Tab/TabPanel";
import { getSuggestionComments } from "../api";
import Comments from "./Comments";
import { useTranslation } from "react-i18next";
export default function CommentsTab({ isActive, tab, handleChangeTab }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { t } = useTranslation();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(async () => {
    getRefreshData();
  }, []);

  function getRefreshData(params) {
    getData().then(async (comments) => {
      setList(comments);
      setLoading(false);
    });
  }

  function getData(parentId = null) {
    const params = {
      SuggestionId: id,
      ParentId: parentId,
      ...getRequestHeader(),
    };
    return getSuggestionComments(params)
      .then((res) => {
        return res.data.data;
        // setList(list_to_tree(res.data.data));
        // let newArr = [...res.data.data];
        // newArr.forEach
        // newArr[0].parentId = 73;
        // newArr[1].parentId = 73;
        // console.log("COMMENTS", list_to_tree(newArr));
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  }

  const comments = list?.filter((i) => i.type === 1) || [],
    questions = list?.filter((i) => i.type === 2) || [];

  return (
    <div id="comments-tab">
      <TabsR value={tab} onChange={handleChangeTab} className="layout">
        <TabR label={`${t("comments")} ${comments.length}`} {...a11yProps(0)} />
        <TabR label={`${t("asks")} ${questions.length}`} {...a11yProps(1)} />
      </TabsR>
      <Divider style={{ marginBottom: 25 }} />
      <TabPanel value={tab} index={0}>
        <EmptyOrShow loading={loading}>
          <Comments
            tab={0}
            isActive={isActive}
            list={comments}
            getData={getData}
            getRefreshData={getRefreshData}
          />
        </EmptyOrShow>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <EmptyOrShow loading={loading}>
          <Comments
            tab={1}
            isActive={isActive}
            list={questions}
            getData={getData}
            getRefreshData={getRefreshData}
          />
        </EmptyOrShow>
      </TabPanel>
    </div>
  );
}
