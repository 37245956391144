import { CalendarToday, NotificationsNoneOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  getRequestHeader,
  getShortTxt,
  toShowDate,
} from "../../components/helpers";
import { getFcmHistory } from "./api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PAGES } from "../../constants";

const TEST = [
  {
    title: "TitleTitleTitleTitle",
    description:
      "Description Description Description Description Description Description",
    createdDate: "2021-03-24T04:52:56.239Z",
  },
];
export default function AlertHistory() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    getFcmHistory(getRequestHeader())
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="alert_history layout">
      <h3>{t("alert")}:</h3>
      {list.map((i, index) => (
        <Card key={index} {...i} />
      ))}
    </div>
  );
}

function Card({ title, description, createdDate, eventName, eventId }) {
  const history = useHistory();

  const handleClick = () => {
    if (eventName === "Vote" && eventId)
      history.push(`${PAGES.votesView}/${eventId}`);
    else if (eventName === "Suggestion" && eventId)
      history.push(`${PAGES.suggestionView}/${eventId}`);
  };

  return (
    <div className="list_card" onClick={handleClick}>
      <div className="icon">
        <NotificationsNoneOutlined />
      </div>
      <div className="list_card__info">
        <h3>{getShortTxt(title, 140)}</h3>
        <p>{description}</p>
        <div className="dates">
          <CalendarToday />
          <span>{toShowDate(createdDate, true)}</span>
        </div>
      </div>
      <div className="list_card__actions"></div>
    </div>
  );
}
