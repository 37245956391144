export const SET_APP_LOADING = "app:setAppLoading";

export const SET_PAGE = "app:setPage";

export const SET_USER = "app:setUser";

export const RESET_STATE = "app:resetState";

export const SET_FILTER = "app:setFilter";

export const SHOW_MENU = "app:showMenu";
export const SHOW_MODAL = "app:showModal";

export const SET_CONSTRUCTOR_DIMENTIONS = "app:setConstructorDimentions";

export const setAppLoading = (loading) => {
  return {
    type: SET_APP_LOADING,
    payload: {
      loading: loading,
    },
  };
};

export const setPage = (page) => {
  return {
    type: SET_PAGE,
    payload: { page },
  };
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: { user },
  };
};

export const resetState = (stateName) => {
  return {
    type: RESET_STATE,
    payload: { stateName },
  };
};

export const setFilter = (filter) => {
  return {
    type: SET_FILTER,
    payload: { filter },
  };
};

export const setShowMenu = (show = false, type = "", data = {}) => {
  return {
    type: SHOW_MENU,
    payload: { show, type, data },
  };
};

export const setShowModal = (show = false, type = "", data = {}) => {
  return {
    type: SHOW_MODAL,
    payload: { show, type, data },
  };
};
