import { Formik } from "formik";
import React, { useState } from "react";
import { AlertR } from "../../../components/design";
import { ButtonR, InputMask, InputText } from "../../../components/form";
import { ERROR, PAGES, SUCCESS } from "../../../constants";
import { getSmsCode, checkSmsCode, changePassword } from "./api";
import * as Yup from "yup";
import Countdown from "react-countdown";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const initialStateConst = {
  phone: "",
  code: "",
  password: "",
  passwordRepeat: "",
};

export default function ForgotPasswordForm() {
  const [showSecond, setShowSecond] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  //Сохранение нового пароля
  function postData(values) {
    const params = {
      Login: values.phone,
      Password: values.password,
      SmsCode: values.code,
      Header: {
        PhoneNumber: values.phone,
        SystemCode: "IAM",
        Token: "",
        Version: 0,
      },
    };

    changePassword(params)
      .then((res) => {
        if (res.data.isSuccess) {
          enqueueSnackbar(t("change_pass_success"), {
            variant: SUCCESS,
          });
          history.push(PAGES.login);
        } else {
          enqueueSnackbar(res.data.errorText, {
            variant: ERROR,
          });
        }
      })
      .catch((err) => {});
  }

  const validate = Yup.object().shape({
    phone: Yup.string().required(t("required")),
    code: Yup.string().required(t("required")),
    password: Yup.string()
      .test("len", t("pass_length"), (val) => val.length >= 6)
      .required(t("required")),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref("password"), null], t("pass_match"))
      .required(t("required")),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        postData(values);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit } = props;

        return (
          <>
            <form onSubmit={handleSubmit} className="mt-4">
              {!showSecond ? (
                <FirstStep {...props} handleNext={() => setShowSecond(true)} />
              ) : (
                <SecondStep
                  {...props}
                  handleBack={() => setShowSecond(false)}
                />
              )}
            </form>
          </>
        );
      }}
    </Formik>
  );
}

//Первый шаг
function FirstStep({ values, handleChange, errors, handleNext }) {
  const [send, setSend] = useState(false);
  const [err, setErr] = useState(null);
  const { t } = useTranslation();

  function handleSendCode() {
    if (values.phone.length === 10) {
      const params = {
        PhoneNumber: values.phone,
        SystemCode: "IAM",
      };

      getSmsCode(params)
        .then((res) => {
          if (res.data.isSuccess) setSend(true);
        })
        .catch((err) => {});
    } else setErr(t("incorrect_num"));
  }

  function handleCheckCode() {
    // setCodeNotCorrect(false);
    setErr("");
    const params = {
      SmsCode: values.code,
      PhoneNumber: values.phone,
      SystemCode: "IAM",
    };

    checkSmsCode(params)
      .then((res) => {
        if (res.data.isSuccess) {
          handleNext();
          setErr("");
          // setCodeNotCorrect(false);
        } else {
          setErr(t("incorrect_code"));
          // setCodeNotCorrect(true);
        }
      })
      .catch((err) => {});
  }

  return (
    <>
      <p>{t("forgot_pass")}</p>
      <div className="d-flex justify-content-between align-items-end">
        <InputMask
          name="phone"
          label={t("phone")}
          placeholder={t("phone_in")}
          containerClass="flex-1 m-r-1"
          value={values.phone}
          error={errors.phone}
          onChange={handleChange}
        />
        <ButtonR
          onClick={handleSendCode}
          variant="contained"
          color="primary"
          disabled={send}
        >
          {t("send_code")}
        </ButtonR>
      </div>
      <InputMask
        name="code"
        label={t("sms_code")}
        placeholder={t("sms_code_in")}
        format="######"
        value={values.code}
        error={errors.code}
        onChange={handleChange}
      />
      {send && (
        <p className="count-down">
          {t("send_code_r")}
          {t("after")}{" "}
          {
            <Countdown
              date={Date.now() + 60000}
              renderer={({ minutes, seconds }) => (
                <span>
                  0{minutes}:{seconds}
                </span>
              )}
              onComplete={() => setSend(false)}
            />
          }
          )
        </p>
      )}

      <AlertR type={ERROR} show={err} label={err} />
      <ButtonR onClick={handleCheckCode} variant="contained" color="primary">
        {t("next")}
      </ButtonR>
    </>
  );
}

//Второй шаг
function SecondStep({ values, handleChange, errors, handleNext, handleBack }) {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("new_pass")}</p>
      <InputText
        name="password"
        label={t("pass")}
        placeholder={t("pass_in")}
        type="password"
        value={values.password}
        error={errors.password}
        onChange={handleChange}
      />
      <InputText
        name="passwordRepeat"
        label={t("pass_r")}
        placeholder={t("pass_r_in")}
        type="password"
        value={values.passwordRepeat}
        error={errors.passwordRepeat}
        onChange={handleChange}
      />
      <ButtonR type="submit" variant="contained" color="primary">
        {t("just_save")}
      </ButtonR>
      <ButtonR onClick={handleBack} variant="contained" color="secondary">
        {t("back")}
      </ButtonR>
    </>
  );
}
