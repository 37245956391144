import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getSuggestionActivityHistory = (params) => {
  return instance(false)
    .post(`/Suggestion/GetSuggestionActivityHistory`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getVoteActivityHistory = (params) => {
  return instance(false)
    .post(`/Vote/GetVoteActivityHistory`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getBonusHistory = (params) => {
  return instance(false)
    .post(`/Profile/v2/GetBonusHistory`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getCityNoveltyBody = (id) => {
  return instance(false)
    .get(`/CityNovelty/GetCityNoveltyBody?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getCityNoveltyImage = (id) => {
  return instance(false)
    .get(`/CityNovelty/GetCityNoveltyAttachment?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
