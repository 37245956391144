import { TrendingFlat } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { DividerR } from "../../components/design";
import { ButtonR } from "../../components/form";
import { getShortTxt, locale, toShowDate } from "../../components/helpers";
import { PAGES } from "../../constants";

export default function TextSlides({ list = [] }) {
  const history = useHistory();
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => {
      let current = {};
      if (list[i]) current = list[i];

      return (
        <div className="text_slides__page">
          {/* <p>0{i + 1}</p> */}
          <h3>{getShortTxt(locale(current, "title"), 70)}</h3>
          <p className="date">{toShowDate(current.publishDate)}</p>
        </div>
      );
    },
  };
  return (
    <div className="text_slides">
      <Slider {...settings}>
        {list.map((i, index) => (
          <div key={index}>
            <BackgroundImage id={i.imageId}>
              {/* <div className="overlay"></div> */}
              <div className="layout">
                <div className="">
                  <p className="date">{toShowDate(i.publishDate)}</p>

                  <DividerR />

                  <h2>{getShortTxt(locale(i, "title"), 300)}</h2>
                  <p className="desc">{locale(i, "description")}</p>
                </div>
                <div className="d-flex">
                  <ButtonR
                    variant="outlined"
                    endIcon={<TrendingFlat />}
                    onClick={() => history.push(PAGES.newsView + "/" + i.id)}
                    style={{ width: 250 }}
                  >
                    {t("more")}
                  </ButtonR>
                  <ButtonR
                    variant="outlined"
                    onClick={() => history.push(PAGES.newsList)}
                    style={{
                      marginLeft: 20,
                      width: 250,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    {t("all")}
                  </ButtonR>
                </div>
              </div>
            </BackgroundImage>
          </div>
        ))}
      </Slider>
      {/* <div className="news_link">
        <Event />
      </div> */}
    </div>
  );
}

function BackgroundImage({ id, children }) {
  const [image, setImage] = useState("/images/top-back.svg");
  // useEffect(() => {
  //   getNewsAttachment(id)
  //     .then((res) => {
  //       setImage("data:image/png;base64," + res.data.data.content);
  //     })
  //     .catch((err) => {});
  // }, []);

  return (
    <div
      className="text_slides__info"
      // style={{
      //   backgroundImage: `url(${image})`,
      // }}
    >
      {children}
    </div>
  );
}
