import { List } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadMore } from "../../../components/design";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import { getVotesList } from "../api";
import VoteCard from "./VoteCard";

export default function Votes({ type, list = [] }) {
  // const [list, setList] = useState([{}, {}, {}]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   getVotesList()
  //     .then((res) => {
  //       setList(res.data.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // }, []);

  function handleLoadMore(params) {
    // setList(list.concat({}));
  }

  return (
    <div className="layout">
      {/* <EmptyOrShow loading={loading}> */}
      <>
        {list.map((i, index) => (
          <VoteCard key={index} {...i} type={type} />
        ))}
        {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
      </>
      {/* </EmptyOrShow> */}
    </div>
  );
}
