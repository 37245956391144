import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TabR from "../../../components/design/TabR";
import TabsR from "../../../components/design/TabsR";
import { a11yProps } from "../../../components/Tab";
import TabPanel from "../../../components/Tab/TabPanel";
import Template from "../../../components/template";
import { LIGHT_TOP, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import History from "./History";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function HistoryList() {
  const [value, setValue] = useState(0);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️🕙 " + `${t("history")}`,
    desc: `${t("activity")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("history") }],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(setPage(PAGE_DATA));
  // }, []);

  // useEffect(() => {
  //   dispatch(setPage(PAGE_DATA));
  // }, [language]);

  return (
    <>
      <TabsR value={value} onChange={handleChange} className="layout">
        <TabR label={t("vote")} {...a11yProps(0)} />
        <TabR label={t("conversation")} {...a11yProps(1)} />
        {/* <TabR label="Проблемы" {...a11yProps(1)} /> */}
      </TabsR>
      <Divider style={{ marginBottom: 25 }} />
      <TabPanel value={value} index={0}>
        <History type={value} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <History type={value} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <History type={value} />
      </TabPanel> */}
    </>
  );
}
