import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import TabR from "../../components/design/TabR";
import TabsR from "../../components/design/TabsR";
import { a11yProps } from "../../components/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import Template from "../../components/template";
import { LIGHT_TOP, TEXT } from "../../constants";
import { setPage } from "../../redux/actions/appActions";
import AlertHistory from "./AlertHistory";
import ProfileSettings from "./ProfileSettings";
import "./styles.scss";
import UserBonus from "./UserBonus";
import { useTranslation } from "react-i18next";
import HistoryList from "../history/list";

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const { tab = 0 } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️👤 " + `${t("myprofile")}`,
    desc: `${t("profile_manage")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("myprofile") }],
  };

  useEffect(() => {
    setValue(parseInt(tab));
  }, [tab]);

  useEffect(() => {
    setLoading(!loading);
    dispatch(setPage(PAGE_DATA));
  }, [language]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, []);

  return (
    <Template>
      <TabsR value={value} onChange={handleChange} className="layout">
        <TabR label={t("setting")} {...a11yProps(0)} />
        <TabR label={t("bonus")} {...a11yProps(1)} />
        <TabR label={t("alert")} {...a11yProps(1)} />
        <TabR label={t("history")} {...a11yProps(1)} />
      </TabsR>
      <Divider style={{ marginBottom: 25 }} />
      <TabPanel value={value} index={0}>
        <ProfileSettings type={value} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserBonus type={value} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AlertHistory type={value} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <HistoryList type={value} />
      </TabPanel>
    </Template>
  );
}
