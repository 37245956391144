import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import { getRequestHeader } from "../../../components/helpers";
import { getSuggestionActivityHistory, getVoteActivityHistory } from "../api";
import HistoryCard from "./HistoryCard";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";

export default function History({ type }) {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    setList([]);
    switch (type) {
      case 0:
        getVoteActivityHistory(getRequestHeader())
          .then((res) => {
            if (res.data.data)
              setList(orderBy(res.data.data, ["actionDate"], ["desc"]));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });

        break;
      case 1:
        getSuggestionActivityHistory(getRequestHeader())
          .then((res) => {
            if (res.data.data)
              setList(orderBy(res.data.data, ["actionDate"], ["desc"]));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
        break;
      default:
        break;
    }
  }, [type]);

  function getTitle(params) {
    switch (type) {
      case 0:
        return t("your_vote");
        break;
      case 1:
        return t("your_talk");
        break;

      default:
        break;
    }
  }

  return (
    <div className="layout history">
      <EmptyOrShow loading={loading}>
        <h3>{getTitle()}</h3>
        {list.map((i, index) => (
          <HistoryCard key={index} {...i} type={type} />
        ))}
      </EmptyOrShow>
      {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
    </div>
  );
}
