import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { isEmpty, locale, toShowDate } from "../../../components/helpers";
import Template from "../../../components/template";
import {
  DARK_TOP,
  DATE,
  LIGHT_TOP,
  PAGES,
  STOP_COVID_IMG_URL,
  TEXT,
} from "../../../constants";
import { resetState, setPage } from "../../../redux/actions/appActions";
import { getStopCovidBody, getStopCovidImage } from "../api";
import StopCovidImage from "./StopCovidImage";
import "./styles.scss";
import { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";

export default function StopCovidView() {
  const [data, setData] = useState({ questions: [], fileIds: [] });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getStopCovidBody(id)
      .then(async (res) => {
        let { imageId } = res.data.data;
        setData(res.data.data);
        let pageData = {
          topType: imageId ? DARK_TOP : LIGHT_TOP,
          title: locale(res.data.data, "title"),
          desc: "",
          descType: TEXT,
          route: [
            { name: t("main"), url: "/" },
            { name: t("covid"), url: PAGES.stopCovidList },
            { name: locale(res.data.data, "title") },
          ],
        };
        if (imageId) {
          pageData.topImage = STOP_COVID_IMG_URL + imageId;
        }
        dispatch(setPage(pageData));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      dispatch(resetState("page"));
    };
  }, []);

  function getImage(imageId) {
    getStopCovidImage(imageId)
      .then((res) => {
        dispatch(
          setPage({
            topImage: "data:image/png;base64," + res.data.data.content,
          })
        );
      })
      .catch((err) => {});
  }

  return (
    <Template loading={loading}>
      <div className="stop_covid__view">
        {locale(data, "description")}

        {locale(data, "link") && (
          <div className="links">
            <a href={locale(data, "link")}>{locale(data, "linkName")}</a>
          </div>
        )}

        {!isEmpty(locale(data, "attachments")) && (
          <SRLWrapper>
            {locale(data, "attachments").map((i, index) => (
              <StopCovidImage key={index} {...i} />
            ))}
          </SRLWrapper>
        )}
      </div>
    </Template>
  );
}
