import {
  RESET_STATE,
  SET_APP_LOADING,
  SET_CURRENT_PAGE,
  SET_FILTER,
  SET_PAGE,
  SET_USER,
  SHOW_MENU,
  SHOW_MODAL,
} from "../actions/appActions";
import _ from "lodash";

const initialState = {
  loading: false,
  user: {
    auth: false,
    phone: "",
    token: "",
  },
  page: {
    topType: "",
    title: "",
    route: [
      {
        name: "",
        url: "",
      },
    ],
    desc: "",
  },
  filter: {
    priceBegin: 0,
    priceEnd: 10000000,
    sortType: "price_asc",
  },
  menu: {
    show: null,
    type: "",
    data: {},
  },
  modal: {
    show: false,
    type: "",
    data: {},
  },
};

export const appReducer = (state = initialState, { type, payload }) => {
  let newState = _.cloneDeep(state);

  switch (type) {
    case RESET_STATE:
      newState[payload.stateName] = _.cloneDeep(
        initialState[payload.stateName]
      );
      return newState;
    case SET_APP_LOADING:
      newState.loading = payload.loading;
      return newState;
    case SET_PAGE:
      newState.page = { ...newState.page, ...payload.page };
      return newState;
    case SET_USER:
      newState.user = { ...newState.user, ...payload.user };
      return newState;
    case SET_FILTER:
      newState.filter = { ...newState.filter, ...payload.filter };
      return newState;

    case SHOW_MENU:
      newState.menu = payload;
      return newState;
    case SHOW_MODAL:
      newState.modal = payload;
      return newState;
    default:
      return state;
  }
};
