import { AccessTime, DoneOutlineOutlined } from "@material-ui/icons";
import { cloneDeep, findIndex } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ButtonR } from "../../../components/form";
import {
  getRequestHeader,
  isEmpty,
  locale,
  obj,
  toShowDate,
} from "../../../components/helpers";
import Template from "../../../components/template";
import {
  DARK_TOP,
  DATE,
  ERROR,
  INFO,
  LIGHT_TOP,
  PAGES,
  SUCCESS,
  VOTES_INFO_MODAL,
  VOTES_MODAL,
  VOTE_IMG_URL,
} from "../../../constants";
import {
  resetState,
  setPage,
  setShowModal,
} from "../../../redux/actions/appActions";
import {
  getVoteBody,
  getVoteImage,
  submitVoteAnswers,
  isVoteAlreadySubmitted,
  getUserAnswers,
} from "../api";
import Question from "./Question";
import "./styles.scss";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import { useTranslation } from "react-i18next";
import TimeIcon from "./TimeIcon";
export default function VotesView({ match }) {
  const [data, setData] = useState({ questions: [] });
  const [formData, setFormData] = useState([]);
  const [isAnswered, setIsAnswered] = useState([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const voteId = match.params.id;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user, page } = useSelector((state) => state.app);

  useEffect(() => {
    getData();

    return () => {
      dispatch(resetState("page"));
    };
  }, [voteId, language]);

  function checkVote(currentStageNumber, isActive) {
    const params = {
      voteId: parseInt(voteId),
      stageNumber: currentStageNumber,
      ...getRequestHeader(),
    };
    isVoteAlreadySubmitted(params).then((res) => {
      // setIsAnswered(res.data.data);
      if (res.data.data) {
        getUserAnswersData(currentStageNumber);
      } else {
        console.log("isVoteAlreadySubmitted", data, res.data.data);
        if (isActive)
          dispatch(
            setShowModal(true, VOTES_INFO_MODAL, {
              title: t("only_one_vote"),
              desc: "",
            })
          );
      }
    });
  }

  function getUserAnswersData(stageNumber) {
    getUserAnswers(parseInt(voteId), stageNumber, getRequestHeader()).then(
      (res) => {
        setIsAnswered(res.data?.data?.questions);
      }
    );
  }

  function isUserAnswer(questionId, answerId) {
    let q = isAnswered.filter((i) => i.questionId === questionId)[0];
    if (q) {
      let a = q.answers.filter((i) => i.answerId === answerId)[0];
      if (a) return true;
    }
    return false;
  }

  function getData() {
    getVoteBody(voteId)
      .then(async (res) => {
        let {
          stage1StartDate,
          stage1EndDate,
          stage2StartDate,
          stage2EndDate,
          imageId,
          totalStageNumber,
          currentStageNumber,
          isActive,
        } = res.data.data;
        setData(res.data.data);
        let pageData = {
          topType: imageId ? DARK_TOP : LIGHT_TOP,
          title: locale(res.data.data, "title"),
          desc:
            totalStageNumber > 1
              ? `${t("period1")} ${toShowDate(
                  stage1StartDate,
                  true
                )} - ${toShowDate(stage1EndDate, true)}
                ${t("period2")} ${toShowDate(
                  stage2StartDate,
                  true
                )} - ${toShowDate(stage2EndDate, true)} \n`
              : `${toShowDate(stage1StartDate, true)} - ${toShowDate(
                  stage1EndDate,
                  true
                )}`,
          descType: DATE,
          route: [
            { name: t("main"), url: "/" },
            { name: t("vote"), url: PAGES.votesList },
            { name: locale(res.data.data, "title") },
          ],
          moreBtn: {
            show: true,
            descShow: false,
          },
        };
        if (imageId) await getImage(imageId);
        await checkVote(currentStageNumber, isActive);
        dispatch(setPage(pageData));
        setLoading(false);

        //Redirect to login
        if (!res.data.isSuccess) history.push(PAGES.login);
      })
      .catch((err) => {
        enqueueSnackbar(t("voteId"), {
          variant: ERROR,
        });
        history.push(PAGES.votesList);
        setLoading(false);
      });
  }

  function getImage(imageId) {
    // getVoteImage(imageId)
    //   .then((res) => {
    //     dispatch(
    //       setPage({
    //         topImage: "data:image/png;base64," + res.data.data.content,
    //       })
    //     );
    //   })
    //   .catch((err) => {});
    dispatch(
      setPage({
        topImage: VOTE_IMG_URL + imageId,
      })
    );
  }

  function handleChangeAnswers({ questionId, answerIds, customAnswer = null }) {
    let obj = { questionId, answerIds };
    if (!isEmpty(customAnswer)) obj.customAnswer = customAnswer;

    let newArr = cloneDeep(formData);
    // let isExist = newArr.filter( i => i.questionId === questionId)[0]
    var index = findIndex(newArr, { questionId });

    if (index > -1) {
      newArr.splice(index, 1, obj);
    } else {
      newArr.push(obj);
    }
    setFormData(newArr);
  }

  function postData() {
    if (!user?.auth) {
      history.push(PAGES.login + "/vote_" + voteId);
      return;
    }
    setSubmitting(true);
    const params = {
      voteId: parseInt(voteId),
      stageNumber: data.currentStageNumber,
      answers: formData,
      ...getRequestHeader(),
    };

    submitVoteAnswers(params)
      .then((res) => {
        setSubmitting(false);
        if (res.data.isSuccess) {
          // enqueueSnackbar(t("vote_status"), {
          //   variant: SUCCESS,
          // });
          getData();

          dispatch(setShowModal(true, VOTES_MODAL));

          // history.push(PAGES.votesList);
        } else {
          handleServerErrors(res.data.errorText, t, (txt) =>
            enqueueSnackbar(txt, {
              variant: ERROR,
            })
          );
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.error("err", err);
      });
  }

  let active = data.isActive && isEmpty(isAnswered);

  return (
    <Template loading={loading}>
      <div className="vote layout">
        {page?.moreBtn?.descShow && (
          <div className="vote__info">
            <p className="label">{t("dist")}</p>
            <p className="info">{locale(obj(data.district), "name")}</p>
            <p className="label">{t("spec")}</p>
            <p className="info">{locale(data, "description")}</p>
            <p className="label">{t("stage_count")}</p>
            <p className="info">
              {data.totalStageNumber > 1
                ? `${data.totalStageNumber} ${t("stages")}`
                : `${data.totalStageNumber}  ${t("stage")}`}
            </p>
            {data.totalStageNumber > 1 && (
              <>
                <p className="label">{t("stage_curr")}</p>
                <p className="info">{data.currentStageNumber}</p>
                <p className="label">{t("stage_status")}</p>
                <div className="info">
                  <div>
                    <span>
                      1 {t("stage")} <TimeIcon date={data.stage1EndDate} />
                    </span>
                    <span>
                      2 {t("stage")}{" "}
                      <TimeIcon
                        date={data.stage2EndDate}
                        opacity={
                          new Date(data.stage2StartDate) < new Date() ? 0.5 : 1
                        }
                      />
                    </span>
                  </div>
                </div>
              </>
            )}
            <p className="label">
              {data.totalStageNumber > 1 && data.currentStageNumber === 2
                ? t("answered_client_count_now")
                : t("answered_client_count")}
            </p>
            <p className="info">{data.answeredClientCount}</p>
          </div>
        )}
        {!active && <h3>{t("vote_res")}</h3>}
        <div className="vote__body">
          {data.questions.map((i, index) => (
            <Question
              key={index}
              stageNumber={data.currentStageNumber}
              isActive={active}
              formData={formData}
              handleChangeAnswers={handleChangeAnswers}
              isUserAnswer={isUserAnswer}
              {...i}
            />
          ))}
          {active && !isEmpty(data.questions) && (
            <div className="d-flex">
              <ButtonR
                variant="contained"
                color="primary"
                startIcon={<EmojiPeopleIcon />}
                onClick={postData}
                disabled={submitting}
              >
                {t("vote_send")}
              </ButtonR>
              <ButtonR
                variant="contained-light"
                color="secondary"
                onClick={() => history.goBack()}
              >
                {t("back")}
              </ButtonR>
            </div>
          )}
        </div>
      </div>
    </Template>
  );
}

function handleServerErrors(errorText = "", t, showErr) {
  const ERRORS = ["There is no Answers", "Answer is required"];
  if (
    errorText.includes("There is no Answers") ||
    errorText.includes("Answer is required") ||
    errorText.includes("The empty Custom Answer is provided")
  )
    return showErr(t("err"));

  return showErr(errorText);
}
