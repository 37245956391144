import React, { useEffect, useState } from "react";
import { CITY_PROGRAM_IMG_URL } from "../../../constants";
import { getCityProgramsImage } from "../api";

export default function CityProgramsImage({ id }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(CITY_PROGRAM_IMG_URL + id);
    // getCityProgramsImage(id)
    //   .then((res) => {
    //     setImage("data:image/png;base64," + res.data.data.content);
    //   })
    //   .catch((err) => {});
  }, []);

  return <img alt="" src={image} />;
}
