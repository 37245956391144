import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as Icon2 } from "../../../assets/images/emergency-icon-2.svg";
import { ReactComponent as Icon3 } from "../../../assets/images/emergency-icon-3.svg";
import { ReactComponent as Icon4 } from "../../../assets/images/emergency-icon-4.svg";
import { ReactComponent as Icon5 } from "../../../assets/images/emergency-icon-5.svg";
import { ReactComponent as Icon6 } from "../../../assets/images/emergency-icon-6.svg";
import { ReactComponent as Icon7 } from "../../../assets/images/emergency-icon-7.svg";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import Template from "../../../components/template";
import { LIGHT_TOP, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import EmergencyCall from "./EmergencyCall";
import "./styles.scss";

export default function EmergencyCallList() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("language", language);
    const PAGE_DATA = {
      topType: LIGHT_TOP,
      title: "️🆘 " + `${t("call")}`,
      desc: `${t("info")}\n\n${t("emergency_call_skype")}`,
      descType: TEXT,
      route: [{ name: t("main"), url: "/" }, { name: t("call") }],
    };
    const CONTACTS = [
      {
        title: t("101"),
        number: "101",
        icon: <Icon2 />,
      },
      {
        title: t("102"),
        number: "102",
        icon: <Icon3 />,
      },
      {
        title: t("103"),
        number: "103",
        icon: <Icon4 />,
      },
      {
        title: t("104"),
        number: "104",
        icon: <Icon5 />,
      },
      {
        title: t("109"),
        number: "109",
        icon: <Icon6 />,
      },
      {
        title: t("112"),
        number: "112",
        icon: <Icon6 />,
      },
      {
        title: t("1308"),
        number: "1308",
        icon: <Icon7 />,
      },
      {
        title: t("tel_akj"),
        number: "+7 727 3007541",
        number2: "+7 727 3007542",
        icon: <Icon7 />,
      },
      {
        title: t("tel_as"),
        number: "+7 727 2746666",
        icon: <Icon7 />,
      },
      {
        title: t("tel_ats"),
        number: "+7 727 3780665",
        number2: "+7 727 3410777",
        icon: <Icon7 />,
      },
      {
        title: t("tel_aes"),
        number: "+7 727 3569999",
        number2: "+7 727 3999900",
        icon: <Icon7 />,
      },
      {
        title: t("tel_tha"),
        number: "+7 727 3970196",
        icon: <Icon7 />,
      },
    ];
    setContacts(CONTACTS);
    dispatch(setPage(PAGE_DATA));
  }, [language]);

  return (
    <Template loading={loading}>
      <EmptyOrShow loading={loading}>
        <EmergencyCall list={contacts} />
      </EmptyOrShow>
    </Template>
  );
}
