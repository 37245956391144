import React from "react";
import "./styles.scss";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";

//Шаблон авторизации
export default function AuthTemplate({ children }) {
  const { t } = useTranslation();
  return (
    <div className="auth">
      <div className="auth__left">
        <div className="auth__left__inner">
          <Logo />
          <h1>{t("welcome")}</h1>
          <p>{t("welcome_desc")}</p>
        </div>
      </div>
      <div className="auth__right">
        <div className="auth__right__inner">{children}</div>
      </div>
    </div>
  );
}
