import instance from "../../../api/api";
import { handleError } from "../../../api/helpers";

export const doLogin = (params) => {
  return instance(false)
    .post(`/Authentication/Authorize`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
