import React, { useEffect, useState } from "react";
import { DividerR, LoadMore } from "../../components/design";
import Header from "../../components/template/header";
import BgrSlider from "./BgrSlider";
import "./styles.scss";
import TextSlides from "./TextSlides";
import { ReactComponent as Icon1 } from "../../assets/images/section-icon.svg";
import { ReactComponent as Icon2 } from "../../assets/images/section-icon-2.svg";
import { ReactComponent as Icon3 } from "../../assets/images/section-icon-3.svg";
import { ReactComponent as Icon4 } from "../../assets/images/section-icon-4.svg";
import { ReactComponent as Icon5 } from "../../assets/images/section-icon-5.svg";
import { ReactComponent as Icon6 } from "../../assets/images/section-icon-6.svg";
import { ReactComponent as Icon7 } from "../../assets/images/section-icon-7.svg";
import { ReactComponent as Icon8 } from "../../assets/images/section-icon-8.svg";
import { ReactComponent as Icon9 } from "../../assets/images/section-icon-9.svg";

import { PlayArrowOutlined } from "@material-ui/icons";
import { ButtonR } from "../../components/form";
import News from "./News";
import { PAGES } from "../../constants";
import { useHistory } from "react-router";
import MenuAnimated from "../../components/template/header/MenuAnimated";
import { getNews } from "./api";
import EmptyOrShow from "../../components/design/EmptyOrShow";
import { scrollToTop } from "../../components/helpers";
import { useTranslation } from "react-i18next";

export default function MainPage() {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const LINKS = [
    {
      title: t("news"),
      url: PAGES.cityNoveltyList,
      icon: <Icon1 />,
    },
    {
      title: t("vote"),
      url: PAGES.votesList,
      icon: <Icon2 />,
    },
    {
      title: t("talk"),
      url: PAGES.suggestionList,
      icon: <Icon3 />,
    },
    {
      title: t("covid"),
      url: PAGES.stopCovidList,
      icon: <Icon4 />,
    },
    {
      title: t("rating"),
      url: PAGES.cityAchievements,
      icon: <Icon5 />,
    },
    {
      title: t("description"),
      url: PAGES.aboutCity,
      icon: <Icon6 />,
    },
    {
      title: t("programms"),
      url: PAGES.cityPrograms,
      icon: <Icon7 />,
    },
    {
      title: t("about"),
      url: PAGES.about,
      icon: <Icon8 />,
    },
  ];

  useEffect(() => {
    getNews()
      .then((res) => {
        setList(res.data.data.slice(0, 4));
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="main_page">
      <EmptyOrShow loading={loading}>
        <MenuAnimated />
        <Header black />
        <TextSlides list={list} />
        <section className="links_section layout">
          <h3>👋 {t("welcome_iazamat")}</h3>
          <DividerR />
          {/* <p>{t("start")}</p> */}
          <div className="d-flex flex-wrap justify-content-between">
            {LINKS.map((i, index) => (
              <div
                className="link_card align-items-center"
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTop();
                  history.push(i.url);
                }}
              >
                <span>{i.icon}</span>
                <p>{i.title}</p>
                {/* <div>
                  <p>{i.title}</p>
                  <ButtonR variant="default" endIcon={<PlayArrowOutlined />}>
                    {t("section")}
                  </ButtonR>
                </div> */}
              </div>
            ))}
            <div
              className="link_card align-items-center"
              onClick={() =>
                (window.location.href = "https://budget.open-almaty.kz/")
              }
            >
              <span>
                <Icon9 />
              </span>
              <p>{t("budjet")}</p>
            </div>
          </div>
        </section>
        {/* <News /> */}
        <footer>
          <span>{t("copyright")}</span>
        </footer>
      </EmptyOrShow>
    </div>
  );
}
