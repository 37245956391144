import React, { useCallback } from "react";
import InputText from "./InputText";
import NumberFormat from "react-number-format";
import { FormControl, InputLabel, TextField } from "@material-ui/core";

function InputMask({
  name,
  label,
  value,
  onChange,
  error,
  className = "m-form",
  variant = "outlined",
  format = "+7 (###) ###-####",
  containerClass = "",
  ...props
}) {
  const inputComp = useCallback(
    (props) => <NumberFormatCustom format={format} {...props} />,
    []
  );

  return (
    <div className={"input_phone " + containerClass}>
      <p>{label}</p>
      <TextField
        fullWidth
        value={value}
        onChange={onChange}
        name={name}
        className={className}
        id={name}
        InputProps={{
          inputComponent: inputComp,
        }}
        helperText={error}
        error={error ? true : false}
        variant={variant}
        {...props}
      />
    </div>
  );
}

export default React.memo(InputMask);

function NumberFormatCustom(props) {
  const { inputRef, onChange, format, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={format}
      mask="_"
    />
  );
}
