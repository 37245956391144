import { format, parse } from "date-fns";
import { MIME_TYPES } from "../../constants";

export const toShowDate = (dateStr = "", withTime = false) => {
  let date = "";
  if (dateStr) {
    if (withTime) date = format(new Date(dateStr), "dd.MM.yyyy HH:mm");
    else date = format(new Date(dateStr), "dd.MM.yyyy");
  }
  return date;
};

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export const arr = (array = []) => {
  if (!isEmpty(array)) return array;
  else return [];
};

export const obj = (obj) => {
  if (!isEmpty(obj)) return obj;
  else return {};
};

export const getRequestHeader = () => {
  return {
    header: {
      phoneNumber: localStorage.getItem("phone"),
      systemCode: "IAM",
      token: localStorage.getItem("token"),
      version: 0,
    },
  };
};

export const getShortTxt = (str = "", length = 80) => {
  let string = "";
  if (!str) return "";
  if (str.length > length) {
    string = str.slice(0, length) + " ...";
  } else {
    string = str;
  }
  return string;
};

export function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].childrens = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== null) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileType = (mime = "") => {
  let type = null;
  MIME_TYPES.forEach((el) => {
    if (mime.includes(el.data)) type = el.type;
  });
  return type;
};

export const scrollToTop = () => {
  document.getElementById("root").scrollIntoView();
};

export const locale = (obj, param) => {
  let l = localStorage.getItem("lang");
  switch (l) {
    case "ru":
      return obj[param + "Ru"];
      break;
    case "kz":
      return obj[param + "Kk"];
      break;

    default:
      return obj[param + "Ru"];
      break;
  }
};

export function validateFileFormat(_validFileExtensions, sFileName) {
  var blnValid = false;
  for (var j = 0; j < _validFileExtensions.length; j++) {
    var sCurExtension = _validFileExtensions[j];
    if (
      sFileName
        .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
        .toLowerCase() == sCurExtension.toLowerCase()
    ) {
      blnValid = true;
      break;
    }
  }

  if (!blnValid) {
    return false;
  }
  return true;
}
