import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import { getRequestHeader } from "../../../components/helpers";
import Template from "../../../components/template";
import { LIGHT_TOP, PAGES, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getBonusList } from "../api";
import Bonus from "./Bonus";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function BonusList() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️🛍 " + `${t("shop")}`,
    desc: `${t("bonus_use")}`,
    descType: TEXT,
    route: [
      { name: t("main"), url: "/" },
      { name: t("myprofile"), url: PAGES.profile },
      { name: t("bonus"), url: PAGES.profile + "/1" },
      { name: t("shop") },
    ],
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    //Получение данных
    getBonusList()
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   dispatch(setPage(PAGE_DATA));
  // }, [language]);

  return (
    <Template loading={loading}>
      <EmptyOrShow loading={loading}>
        <Bonus type={value} list={list} />
      </EmptyOrShow>
    </Template>
  );
}
