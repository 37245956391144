import { Button, withStyles } from "@material-ui/core";
import React from "react";
import { ReactComponent as LoadIcon } from "../../assets/images/load.svg";

const LoadButton = withStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 20,
    lineHeight: "28px",
    color: "#161A17",
    textTransform: "none",
    borderBottom: "2px solid #2BB472",
    borderRadius: 0,
    marginRight: 10,
    "&:hover": {
      borderBottom: "2px solid #2BB472",
      backgroundColor: "transparent",
      borderRadius: 0,
    },
  },
}))(Button);

export default function LoadMore({
  label = "Загрузить ещё",
  onClick,
  withIcon = false,
}) {
  return (
    <div className="load_more" onClick={onClick}>
      <LoadButton>{label}</LoadButton>
      {withIcon && (
        <div className="load_more__icon">
          <LoadIcon />
        </div>
      )}
    </div>
  );
}
