import React, { useEffect, useState } from "react";
import QuestionForm from "./form";
import { getVoteShortResults } from "../api";
import { useParams } from "react-router";
import { arr, isEmpty, locale, obj } from "../../../components/helpers";
import AnswerLabel from "./AnswerLabel";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlineRounded } from "@material-ui/icons";
import Answers from "./Answers";
import ImageOrDoc from "../../../components/design/ImageOrDoc";
import { VOTE_IMG_URL } from "../../../constants";

export default function Question({
  voteId,
  id,
  stageNumber,
  answers = [],
  isActive = false,
  isMultiple,
  isRequired,
  formData = [],
  handleChangeAnswers,
  isCustomAnswerAllowed,
  maxMultipleChoice,
  isUserAnswer,
  ...props
}) {
  let answerForm = formData.filter((j) => j.questionId === id)[0];
  return (
    <div className="vote_questions">
      <p className="vote_questions__title wrap">
        {locale(props, "question")} {isRequired && " *"}
      </p>
      <div className="vote_questions__files">
        {locale(props, "attachments")?.map((i, index) => (
          <ImageOrDoc key={i.id} {...i} baseUrl={VOTE_IMG_URL} />
        ))}
      </div>
      {isActive ? (
        <QuestionForm
          questionId={id}
          answers={answers}
          answerForm={answerForm}
          handleChangeAnswers={handleChangeAnswers}
          isCustomAnswerAllowed={isCustomAnswerAllowed}
          isMultiple={isMultiple}
          isRequired={isRequired}
          maxMultipleChoice={maxMultipleChoice}
        />
      ) : (
        <Answers
          voteId={voteId}
          questionId={id}
          stageNumber={stageNumber}
          answerTexts={answers}
          isUserAnswer={isUserAnswer}
        />
      )}
    </div>
  );
}
