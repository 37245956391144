import { Divider, withStyles } from "@material-ui/core";
import React from "react";

const StyledDivider = withStyles((theme) => ({
  root: {
    backgroundColor: "#2BB472",
    height: 2,
    width: 50,
  },
}))(Divider);

const VerticalDivider = withStyles((theme) => ({
  root: {
    backgroundColor: "#161A17",
  },
}))(Divider);

export default function DividerR(props) {
  if (props.orientation === "vertical") return <VerticalDivider {...props} />;
  return <StyledDivider {...props} />;
}
