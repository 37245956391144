import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getStopCovidList = () => {
  return instance(false)
    .get(`/StopCovid/GetStopCovidTitles`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getStopCovidBody = (id) => {
  return instance(false)
    .get(`/StopCovid/GetStopCovidBody?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getStopCovidImage = (id) => {
  return instance(false)
    .get(`/StopCovid/GetStopCovidAttachment?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getStopCovidDailyStatistics = () => {
  return instance(false, "https://covid19.smartalmaty.kz/api")
    .get(`/snapshots/dailypatients`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getStopCovidLocalStatistics = () => {
  return instance(false, "https://covid19.smartalmaty.kz/api")
    .get(`/snapshots/localpatients`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
