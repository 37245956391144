import { CalendarToday, InfoOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { ButtonR } from "../../components/form";
import {
  getRequestHeader,
  getShortTxt,
  locale,
  toShowDate,
} from "../../components/helpers";
import BonusBanner from "../../assets/images/bonus-banner.png";
import { getBonusDescription, getBonusHistory } from "./api";
import EmptyOrShow from "../../components/design/EmptyOrShow";
import { DEFAULT_MODAL, PAGES } from "../../constants";
import { useHistory } from "react-router";
import { setShowModal } from "../../redux/actions/appActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function UserBonus() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getBonusHistory(getRequestHeader())
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  function getBonusTotal() {
    let total = 0;
    list.forEach((e) => {
      total = total + parseInt(e.amount);
    });
    return total;
  }

  function handleClick() {
    getBonusDescription().then((res) => {
      dispatch(
        setShowModal(true, DEFAULT_MODAL, {
          title: t("bonus_desc"),
          desc: locale(res.data.data, "description"),
        })
      );
    });
  }

  return (
    <div className="user_bonus layout">
      <EmptyOrShow loading={loading}>
        <div className="user_bonus__banner">
          <div className="info">
            <h3>
              {getBonusTotal()} {t("calc")}
            </h3>
            <p>
              {t("bonus_amount")}{" "}
              <span onClick={handleClick}>
                <InfoOutlined />
              </span>
            </p>
            <ButtonR
              variant="contained"
              color="primary"
              onClick={() => history.push(PAGES.bonusList)}
            >
              {t("use_bonus")}
            </ButtonR>
          </div>
          <div>
            <img alt="" src={BonusBanner} />
          </div>
        </div>
        <div className="user_bonus__history">
          <h3>{t("history_bonus")}</h3>
          {list.map((i, index) => (
            <Card key={index} {...i} />
          ))}
        </div>
      </EmptyOrShow>
    </div>
  );
}

function Card({ bonus, createdDate, amount, subjectId, ...props }) {
  const history = useHistory();

  const handleClick = () => {
    if (bonus?.type === 2 && subjectId)
      history.push(`${PAGES.votesView}/${subjectId}`);
    else if (bonus?.type === 3 && subjectId)
      history.push(`${PAGES.suggestionView}/${subjectId}`);
  };
  return (
    <div className="list_card" onClick={handleClick}>
      <div className="icon">{amount}</div>
      <div className="list_card__info">
        <h3>{locale(props, "detailedDescription")}</h3>
        <div className="dates">
          <CalendarToday />
          <span>{toShowDate(createdDate, true)}</span>
        </div>
      </div>
      <div className="list_card__actions"></div>
    </div>
  );
}
