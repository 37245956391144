import { CalendarToday, CheckCircleOutlineRounded } from "@material-ui/icons";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Thumb from "../../../components/design/list/Thumb";
import { ButtonR } from "../../../components/form";
import { getShortTxt, locale, toShowDate } from "../../../components/helpers";
import { PAGES, VOTE_IMG_URL } from "../../../constants";
import { getVoteAttachmentAsFile, getVoteImage } from "../api";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const ACTIVE_TYPE = 0;

export default function VoteCard({
  id,
  imageId,
  startDate = "",
  endDate = "",
  type,
  isVoted,
  ...props
}) {
  const history = useHistory();

  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (imageId) {
      setImage(VOTE_IMG_URL + imageId);
      // getVoteImage(imageId)
      //   .then((res) => {
      //     setImage("data:image/png;base64," + res.data.data.content);
      //   })
      //   .catch((err) => {});
    }
  }, []);

  function handleClick(params) {
    window.stop();
    history.push(`${PAGES.votesView}/${id}`);
  }

  return (
    <div className="list_card" onClick={handleClick}>
      <Thumb id={imageId} image={image} />
      <div className="list_card__info">
        <h3>{getShortTxt(locale(props, "title"), 140)}</h3>
        <div className="dates">
          <CalendarToday />
          <span>
            {toShowDate(startDate, true)} - {toShowDate(endDate, true)}
          </span>
        </div>
        <p>{locale(props, "description")}</p>
      </div>
      <div className="list_card__actions">
        <ButtonR
          variant={type === ACTIVE_TYPE ? "outlined" : "outlined-red"}
          color="primary"
          startIcon={<EmojiPeopleIcon />}
        >
          {type === ACTIVE_TYPE ? t("voting") : t("finished")}
        </ButtonR>
        {isVoted && (
          <CheckCircleIcon
            style={{
              marginLeft: "auto",
              color: "#2BB472",
              display: "block",
              marginTop: 20,
            }}
          />
        )}
      </div>
    </div>
  );
}
