import axios from "axios";
import { API_URL } from "../constants";

const instance = (authorization = true, url = null) => {
  const axiosInstance = axios.create({
    baseURL: url || API_URL,
  });

  if (authorization) {
    axiosInstance.interceptors.request.use(
      (config) => {
        // config.headers.Authorization = `Bearer ${window.localStorage.getItem(
        //   "token"
        // )}`;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  return axiosInstance;
};

export default instance;
