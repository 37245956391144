import React from "react";
import EmergencyCallCard from "./EmergencyCallCard";

export default function EmergencyCall({ type, list = [] }) {
  return (
    <div className="layout stop_covid">
      {list.map((i, index) => (
        <EmergencyCallCard key={index} {...i} type={type} />
      ))}
      {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
    </div>
  );
}
