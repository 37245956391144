import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getVotesList = (params, paging = {}) => {
  return instance(false)
    .post(`/Vote/v2/GetVoteTitles`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getVoteBody = (id) => {
  return instance(false)
    .get(`/Vote/GetVoteBody?voteId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getVoteImage = (id) => {
  return instance(false)
    .get(`/Vote/GetVoteAttachment?imageId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getVoteAttachmentAsFile = (id) => {
  return instance(false)
    .get(`/Vote/GetVoteAttachmentAsFile?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const submitVoteAnswers = (params) => {
  return instance(false)
    .post(`/Vote/SubmitVoteAnswers`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getVoteShortResults = (id, stageNumber) => {
  return instance(false)
    .get(`/Vote/GetVoteShortResults?voteId=${id}&stageNumber=${stageNumber}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const isVoteAlreadySubmitted = (params) => {
  return instance(false)
    .post(`/Vote/IsVoteAlreadySubmitted`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getUserAnswers = (id, stage, params) => {
  return instance(false)
    .post(`/Vote/GetUserAnswers?voteId=${id}&stageNumber=${stage}`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
