import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getNewsList = (params) => {
  return instance(false)
    .get(`/News/GetNewsTitlesLite?${params}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getNewsBody = (id) => {
  return instance(false)
    .get(`/News/v2/GetNewsBody?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getNewsImage = (id) => {
  return instance(false)
    .get(`/News/GetNewsAttachment?attachmentId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
