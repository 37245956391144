import React, { useEffect, useState } from "react";
import { getNewsImage } from "../api";

export default function NewsImage({ id }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    getNewsImage(id)
      .then((res) => {
        setImage("data:image/png;base64," + res.data.data.content);
      })
      .catch((err) => {});
  }, []);

  return <img alt="" src={image} />;
}
