import { FormControl, FormControlLabel, RadioGroup } from "@material-ui/core";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { AlertR } from "../../../components/design";
import RadioR from "../../../components/design/RadioR";
import { ButtonR, InputText } from "../../../components/form";
import UploadFile from "../../../components/form/UploadFile";
import {
  arr,
  locale,
  obj,
  toBase64,
  validateFileFormat,
} from "../../../components/helpers";
import { ERROR, IMG } from "../../../constants";
import AnswerLabel from "./AnswerLabel";
import { validate } from "./validate";

import { useTranslation } from "react-i18next";
import CheckR from "../../../components/design/CheckR";

const initialStateConst = {
  selectedAnswer: null,
  customAnswer: "",
};
export default function QuestionForm({
  questionId,
  isRequired,
  answers,
  answerForm = { answerIds: [], customAnswer: {} },
  handleChangeAnswers,
  isCustomAnswerAllowed,
  isMultiple,
  maxMultipleChoice = 1,
}) {
  const [isFirstText, setIsFirstText] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getLabelClassName = (values, id) => {
    if (parseInt(values.selectedAnswer) === id)
      return "vote_question__label selected";
    return "vote_question__label";
  };

  function handleChange(value, isCustom = false, files = []) {
    if (isFirstText === null) setIsFirstText(isCustom);

    let newAnswers = new Set(answerForm.answerIds);
    let params = {
      questionId: questionId,
      answerIds: [...answerForm.answerIds],
    };
    if (isCustomAnswerAllowed)
      params.customAnswer = { ...answerForm.customAnswer };

    if (isCustom) {
      params.customAnswer = { answer: value, attachments: files };
      if (isMultiple) {
        if (isFUll(params, newAnswers)) {
          newAnswers.delete([...newAnswers][0]);
        }
        params.answerIds = [...newAnswers];
      } else {
        params.answerIds = [];
        newAnswers = new Set();
      }
    } else {
      if (newAnswers.has(value)) {
        newAnswers.delete(value);
      } else {
        if (isMultiple) {
          newAnswers.add(value);
          if (isFUll(params, newAnswers)) {
            if (isFirstText) {
              if (!isEmpty(params.customAnswer)) params.customAnswer = {};
              else newAnswers.delete([...newAnswers][0]);
            } else newAnswers.delete([...newAnswers][0]);
          }
        } else {
          newAnswers = new Set([value]);
          if (isCustomAnswerAllowed) params.customAnswer = {};
        }
      }

      params.answerIds = [...newAnswers];
    }

    if (isFUll(params, newAnswers)) {
      enqueueSnackbar(t("vote_err"), {
        variant: ERROR,
      });
    } else handleChangeAnswers(params);
  }

  function isFUll(params, newAnswers) {
    let size = newAnswers.size;
    let maxChoice = maxMultipleChoice;
    if (obj(params.customAnswer).answer) size = size + 1;

    if (!isMultiple) {
      maxChoice = 1;
    }

    if (size > maxChoice) {
      return true;
    }
    return false;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        // onPostData();
      }}
    >
      {(props) => {
        let { values, errors, handleSubmit } = props;

        return (
          <form onSubmit={handleSubmit}>
            <FormControl className="w-100" error={errors.selectedAnswer}>
              <RadioGroup aria-label="answers" name="selectedAnswer">
                {answers.map((i, index) => (
                  <FormControlLabel
                    key={index}
                    value={i.id}
                    control={
                      isMultiple ? (
                        <CheckR
                          checked={answerForm.answerIds.includes(i.id)}
                          onClick={() => handleChange(i.id, false)}
                        />
                      ) : (
                        <RadioR
                          checked={answerForm.answerIds.includes(i.id)}
                          onClick={() => handleChange(i.id, false)}
                        />
                      )
                    }
                    className={getLabelClassName(values, i.id)}
                    label={
                      <AnswerLabel
                        text={locale(i, "answer")}
                        files={locale(i, "attachments")}
                        onClick={() => handleChange(i.id, false)}
                      />
                    }
                  />
                ))}
              </RadioGroup>
              {isCustomAnswerAllowed && (
                <div>
                  <InputText
                    name="customAnswer"
                    placeholder={t("own_answer")}
                    value={obj(answerForm.customAnswer).answer}
                    onChange={(e) => {
                      handleChange(
                        e.target.value,
                        true,
                        obj(answerForm.customAnswer).attachments
                      );
                    }}
                    multiline
                    maxLength={1000}
                  />
                  <div style={{ width: "70%", fontSize: 12, marginBottom: 15 }}>
                    {t("custom_desc")}
                  </div>
                  <UploadFile
                    name={"vote_upload" + questionId}
                    label={
                      obj(arr(obj(answerForm.customAnswer).attachments)[0]).name
                    }
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, image/*"
                    onChange={async (e) => {
                      let file = null;

                      if (e.target.files) file = e.target.files[0];
                      if (file === "DELETE") {
                        handleChange(
                          obj(answerForm.customAnswer).answer,
                          true,
                          []
                        );
                      } else if (file) {
                        if (file?.size > 10000000) {
                          enqueueSnackbar(t("file_err"), {
                            variant: ERROR,
                          });
                          return;
                        }

                        if (
                          !validateFileFormat(
                            [".jpg", ".jpeg", ".pdf", ".ppt", ".pptx", ".png"],
                            file?.name
                          )
                        ) {
                          enqueueSnackbar(t("file_err2"), {
                            variant: ERROR,
                          });
                          return;
                        }

                        let base64 = await toBase64(file);

                        handleChange(
                          obj(answerForm.customAnswer).answer,
                          true,
                          [
                            {
                              content: base64.replace(
                                "data:" + file.type + ";base64,",
                                ""
                              ),
                              name: file.name,
                              mimeType: file.type,
                            },
                          ]
                        );
                      }
                    }}
                  >
                    <ButtonR
                      variant="contained"
                      color="primary"
                      style={{ zIndex: "-1" }}
                    >
                      {t("load_file")}
                    </ButtonR>
                  </UploadFile>
                </div>
              )}
              <AlertR
                type={ERROR}
                show={errors.selectedAnswer}
                label={errors.selectedAnswer}
              />
            </FormControl>
          </form>
        );
      }}
    </Formik>
  );
}
