import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ShareBtns from "../../../components/design/ShareBtns";
import { ButtonR } from "../../../components/form";
import { setShowModal } from "../../../redux/actions/appActions";

export default function VotesInfoModal({ title, desc }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="def_modal votes_info_modal">
      <h3>{title} </h3>
      <p>{desc}</p>

      <div className="d-flex">
        <ButtonR
          variant="contained"
          color="primary"
          onClick={() => dispatch(setShowModal(false))}
        >
          {t("ok")}
        </ButtonR>
      </div>
    </div>
  );
}
