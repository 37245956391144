import React from "react";
import EmptyOrShow from "../design/EmptyOrShow";
import ModalR from "../design/ModalR";
import Footer from "./footer";
import Header from "./header/";
import MenuAnimated from "./header/MenuAnimated";
import Main from "./main/";
import "./styles.scss";

export default function Template({ children, loading = false }) {
  return (
    <>
      <MenuAnimated />
      <EmptyOrShow loading={loading}>
        <Header black />
        <Main>{children}</Main>
        <Footer />
        <ModalR />
      </EmptyOrShow>
    </>
  );
}
