import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { ButtonR } from "../form";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Select campaign settings", "Create an ad group", "Create an ad"];
}

export default function HorizontalStepper({
  getStepContent,
  stepCount = 3,
  showSkip = false,
  showBack = false,
  finishText = "",
  handleSubmit,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === stepCount - 1) {
      handleSubmit();
      setActiveStep(0);
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderSteps = () => {
    const items = [];
    for (let index = 0; index < stepCount; index++) {
      const stepProps = {};
      if (isStepSkipped(index)) {
        stepProps.completed = false;
      }
      items.push(
        <Step key={index} {...stepProps}>
          <StepLabel></StepLabel>
        </Step>
      );
    }
    return items;
  };

  return (
    <div className={classes.root}>
      {/* <Stepper activeStep={activeStep}>
        {renderSteps()}
      </Stepper> */}
      <div>
        {activeStep === stepCount ? (
          <div>
            <div className={classes.instructions}>Фото</div>
            <Button onClick={handleReset} className={classes.button}>
              Обнулить
            </Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
            <div className="d-flex">
              <ButtonR onClick={handleNext} variant="contained" color="primary">
                {activeStep === stepCount - 1 ? finishText : "Далее"}
              </ButtonR>
              <ButtonR
                onClick={handleBack}
                variant="contained"
                color="secondary"
              >
                Назад
              </ButtonR>
            </div>
            {/* {showBack && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
              )} */}
            {/* {isStepOptional(activeStep) && showSkip && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Дальше
                </Button>
              )} */}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className="process-actions btn-design w-100"
              style={{ position: "relative", left: 0 }}
              fullWidth
            >
              {activeStep === stepCount - 1 ? "Отправить" : "Дальше"}
            </Button> */}
          </div>
        )}
      </div>
    </div>
  );
}
