import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getUserProfile = (params) => {
  return instance(false)
    .post(`/Profile/GetProfile`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getVoteActivityHistory = (params) => {
  return instance(false)
    .post(`/Vote/GetVoteActivityHistory`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getBonusHistory = (params) => {
  return instance(false)
    .post(`/Profile/v2/GetBonusHistory`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getFcmHistory = (params) => {
  return instance(false)
    .post(`/Profile/GetFcmHistory`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getProfilePhoto = (params) => {
  return instance(false)
    .post(`/Profile/GetProfilePhoto`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getBonusDescription = () => {
  return instance(false)
    .get(`/Bonus/GetBonusDescription`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const updateProfilePhoto = (params) => {
  return instance(false)
    .post(`/Profile/UpdateProfilePhoto`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const updateProfile = (params) => {
  return instance(false)
    .post(`/Profile/UpdateProfile`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const updatePassword = (params) => {
  return instance(false)
    .post(`/Profile/UpdatePassword`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
