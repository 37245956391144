import axios from "axios";
import instance from "./api";
// import LocalStorageService from "./localStorage";

const refreshTokenEndpoint = "/auth/api/token/refresh/";
// const localStorageService = LocalStorageService.getService();

// const tokenRefresher = (error) => {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     const refresh = localStorageService.getRefreshToken();
//     if (!refresh) {
//       window.location.href = "/#/login";
//     }
//     return instance(false)
//       .post(refreshTokenEndpoint, {
//         refresh,
//       })
//       .then((res) => {
//         console.log("resfreshToken", res);
//         if (res.status >= 200) {
//           // localStorageService.setToken(res.data);

//           return instance(true)(originalRequest);
//         }
//       })
//       .catch((err) => {
//         window.location.href = "/#/login";
//       });
//   }
// };

export const handleError = (error) => {
  let err = false,
    message = "";

  if (axios.isCancel(error)) {
    return;
  }

  switch (parseInt(error.response.status)) {
    case 404:
      break;
    case 401:
      window.location.href = "/#/login";
    // return tokenRefresher(error);

    case 400:
      break;
    case 500:
      throw new Error(`Ошибка! 500`);
    default:
      break;
  }
  // return { error: err, message: message };
};

export function SendDataException(message) {
  this.message = message;
  this.name = "Ошибка при отправке!";
  return;
}
