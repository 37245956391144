import { Card, CardActions, CardContent, CardHeader } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ButtonR from "../../../components/form/ButtonR";
import {
  getRequestHeader,
  isEmpty,
  toShowDate,
} from "../../../components/helpers";
import { ERROR, PAGES, SUCCESS } from "../../../constants";
import { submitSuggestionCommentRating } from "../api";

import { ReactComponent as ArrowUp } from "../../../assets/images/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/arrow-down.svg";
import CommentForm from "./form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

export default function Comment({
  id,
  authorName,
  comment,
  createdDate,
  childrens = [],
  tab,
  isActive = false,
  getData,
  likes,
  dislikes,
  replyTo = null,
  getRefreshData,
  refreshYourself = null,
}) {
  const [showForm, setShowForm] = useState(false);
  const [updateChilds, setUpdateChilds] = useState(false);
  const [childs, setChilds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.app);
  const history = useHistory();
  const urlParams = useParams();

  const { t } = useTranslation();
  useEffect(() => {
    getData(id).then((c) => setChilds(c));
  }, []);

  function scrollToCommentFrom(params) {
    const commentFrom = document.getElementById("comments-form-" + tab);
    commentFrom.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  function submitLike(params) {
    submitSuggestionCommentRating();
  }

  function handleLike(isLike = false) {
    if (!user?.auth) {
      history.push(PAGES.login + "/suggestion_" + urlParams.id);
      return;
    }
    const params = {
      CommentId: id,
      IsLike: isLike,
      ...getRequestHeader(),
    };
    submitSuggestionCommentRating(params)
      .then((res) => {
        if (refreshYourself !== null) refreshYourself();
        if (res.data.isSuccess) {
          getRefreshData();

          // enqueueSnackbar("Ваш голос принят!", {
          //   variant: SUCCESS,
          // });
        } else {
          if (isActive)
            enqueueSnackbar(t("already_voted"), {
              variant: ERROR,
            });
          else
            enqueueSnackbar(t("suggestion_disabled"), {
              variant: ERROR,
            });
        }
      })
      .catch((err) => {});
  }

  return (
    <div className="comment_container">
      <Card className="comment">
        <CardHeader
          avatar={<AccountCircle />}
          // action={<p className="dates">{toShowDate(createdDate, true)}</p>}
          title={
            <div className="d-flex align-items-center justify-content-between">
              <span className="title"> {authorName}</span>
              <span className="dates">{toShowDate(createdDate, true)}</span>
            </div>
          }
        />
        <CardContent>
          <p className="wrap">
            {replyTo && <span className="bold">{replyTo + ", "}</span>}{" "}
            {comment}
          </p>
        </CardContent>
        <CardActions disableSpacing>
          {isActive && (
            <ButtonR variant="default" onClick={() => setShowForm(!showForm)}>
              {t("reply")}
            </ButtonR>
          )}
          <div className="rating m-l-auto">
            <ButtonR
              variant="default"
              startIcon={<ArrowUp />}
              disabled={!isActive}
              onClick={() => handleLike(true)}
            >
              {likes}
            </ButtonR>
            <ButtonR
              variant="default"
              startIcon={<ArrowDown />}
              disabled={!isActive}
              onClick={() => handleLike(false)}
            >
              {dislikes}
            </ButtonR>
          </div>
        </CardActions>
      </Card>
      <div className="comment_reply">
        {showForm && (
          <CommentForm
            tab={tab}
            parentCommentId={id}
            handleSend={() => setShowForm(false)}
          />
        )}
      </div>
      <div className="comments__childs">
        {childs.map((i, index) => (
          <Comment
            key={i.id}
            tab={tab}
            isActive={isActive}
            getData={getData}
            replyTo={authorName}
            refreshYourself={() => getData(id).then((c) => setChilds(c))}
            {...i}
          />
        ))}
      </div>
    </div>
  );
}
