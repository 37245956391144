import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AlertR } from "../../../components/design";
import { ButtonR, InputMask, InputText } from "../../../components/form";
import { isEmpty } from "../../../components/helpers";
import { ERROR, PAGES } from "../../../constants";
import { doRegister } from "./api";
import FirstStep from "./FirstStep";
import * as Yup from "yup";

const initialStateConst = {
  phone: "",
  lastName: "",
  firstName: "",
  middleName: "",
  email: "",
  password: "",
  passwordRepeat: "",
};

export default function RegisterForm() {
  const [step, setStep] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  const validate = Yup.object().shape({
    phone: Yup.string().required(t("required")),
    lastName: Yup.string().required(t("required")),
    firstName: Yup.string().required(t("required")),
    // email: Yup.string().required("Необходимо заполнить поле"),
    password: Yup.string()
      .test("len", t("pass_length"), (val) => val.length >= 6)
      .required(t("required")),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref("password"), null], t("pass_match"))
      .required(t("required")),
  });

  //Сохранение данных
  function postData(values) {
    const params = {
      PhoneNumber: values.phone,
      Password: values.password,
      SmsCode: values.code,
      Surname: values.lastName,
      Name: values.firstName,
      FatherName: values.middleName,
      Email: values.email,
      Header: {
        PhoneNumber: values.phone,
        SystemCode: "IAM",
        Token: "",
        Version: 0,
      },
    };

    doRegister(params)
      .then((res) => {
        if (res?.data?.isSuccess) {
          history.push(PAGES.login);
        } else {
          enqueueSnackbar(res.data.errorText, {
            variant: ERROR,
          });
        }
      })
      .catch((err) => {});
  }

  //Получение шагов
  function getSteps(props) {
    switch (step) {
      case 1:
        return <FirstStep {...props} handleNext={() => setStep(step + 1)} />;
      case 2:
        return <SecondStep {...props} handleNext={() => setStep(step + 1)} />;
      case 3:
        return (
          <ThirdStep
            {...props}
            handleNext={() => setStep(step + 1)}
            handleBack={() => setStep(step - 1)}
          />
        );

      default:
        break;
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        postData(values);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit } = props;

        return (
          <form onSubmit={handleSubmit} className="mt-4">
            {getSteps(props)}
          </form>
        );
      }}
    </Formik>
  );
}

//Второй шаг
function SecondStep({
  values,
  handleChange,
  handleNext,
  errors,
  validateField,
}) {
  const { t } = useTranslation();

  function goNext(params) {
    if (values.firstName && values.lastName) handleNext();
    validateField("lastName");
    validateField("firstName");
  }
  return (
    <>
      <InputMask
        name="phone"
        label={t("phone") + " *"}
        placeholder={t("phone_in")}
        value={values.phone}
        error={errors.phone}
        disabled
        onChange={handleChange}
        maxLength={255}
      />
      <InputText
        name="lastName"
        label={t("surname") + " *"}
        placeholder={t("surname_in")}
        value={values.lastName}
        error={errors.lastName}
        onChange={handleChange}
        maxLength={255}
      />
      <InputText
        name="firstName"
        label={t("name") + " *"}
        placeholder={t("name_in")}
        value={values.firstName}
        error={errors.firstName}
        onChange={handleChange}
        maxLength={255}
      />
      <InputText
        name="middleName"
        label={t("fathername")}
        placeholder={t("fathername_in")}
        value={values.middleName}
        error={errors.middleName}
        onChange={handleChange}
        maxLength={255}
      />
      <InputText
        name="email"
        label="Email"
        placeholder={t("email_in")}
        value={values.email}
        error={errors.email}
        onChange={handleChange}
        maxLength={255}
      />
      <ButtonR onClick={goNext} variant="contained" color="primary">
        {t("next")}
      </ButtonR>
    </>
  );
}

//Третий шаг
function ThirdStep({ values, handleChange, handleBack, errors }) {
  const { t } = useTranslation();
  return (
    <>
      <InputText
        name="password"
        label={t("pass") + " *"}
        placeholder={t("pass_in")}
        type="password"
        value={values.password}
        error={errors.password}
        onChange={handleChange}
        maxLength={255}
      />
      <InputText
        name="passwordRepeat"
        label={t("pass_r") + " *"}
        placeholder={t("pass_r_in")}
        type="password"
        value={values.passwordRepeat}
        error={errors.passwordRepeat}
        onChange={handleChange}
        maxLength={255}
      />
      {/* <InputMask
        name="code"
        label="Код из СМС:"
        placeholder="Введите 4-х значный код из СМС"
        format="######"
        value={values.code}
        error={errors.code}
        onChange={handleChange}
      /> */}
      <AlertR
        type={ERROR}
        show={!isEmpty(errors)}
        label={"Заполните все объязательные поля!"}
      />
      <ButtonR type="submit" variant="contained" color="primary">
        {t("register")}
      </ButtonR>
      <ButtonR onClick={handleBack} variant="contained-light" color="secondary">
        {t("back")}
      </ButtonR>
    </>
  );
}
