import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ShareBtns({ title, description, url }) {
  return (
    <div style={{ display: "flex", margin: 10 }}>
      <FacebookShareButton url={url} title={title} description={description}>
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} description={description}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title} description={description}>
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>
      {/* 
      <EmailShareButton url={url} title={title} description={description}>
        <EmailIcon size={40} round={true} />
      </EmailShareButton> */}

      <MailruShareButton url={url} title={title} description={description}>
        <MailruIcon size={40} round={true} />
      </MailruShareButton>

      <OKShareButton url={url} title={title} description={description}>
        <OKIcon size={40} round={true} />
      </OKShareButton>

      <TelegramShareButton url={url} title={title} description={description}>
        <TelegramIcon size={40} round={true} />
      </TelegramShareButton>
    </div>
  );
}
