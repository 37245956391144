import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import TabR from "../../../components/design/TabR";
import TabsR from "../../../components/design/TabsR";
import { getRequestHeader } from "../../../components/helpers";
import { a11yProps } from "../../../components/Tab";
import TabPanel from "../../../components/Tab/TabPanel";
import Template from "../../../components/template";
import { LIGHT_TOP, PAGES, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getSuggestionList } from "../api";
import Suggestions from "./Suggestions";
import { useTranslation } from "react-i18next";

export default function SuggestionList() {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️💬️ " + `${t("talk")}`,
    desc: `${t("talk_more")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("talk") }],
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    getSuggestionList({ header: null })
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
        //Redirect to login
        // if (!res.data.isSuccess) history.push(PAGES.login);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   dispatch(setPage(PAGE_DATA));
  // }, [language]);

  return (
    <Template loading={loading}>
      <TabsR value={value} onChange={handleChange} className="layout">
        <TabR label={t("active")} {...a11yProps(0)} />
        <TabR label={t("finish2")} {...a11yProps(1)} />
      </TabsR>
      <Divider style={{ marginBottom: 25 }} />
      <TabPanel value={value} index={0}>
        <EmptyOrShow loading={loading}>
          <Suggestions
            type={value}
            list={list.filter((i) => i.isActive === true)}
          />
        </EmptyOrShow>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmptyOrShow loading={loading}>
          <Suggestions
            type={value}
            list={list.filter((i) => i.isActive === false)}
          />
        </EmptyOrShow>
      </TabPanel>
    </Template>
  );
}
