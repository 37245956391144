import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { isEmpty, locale, toShowDate } from "../../../components/helpers";
import Template from "../../../components/template";
import {
  CITY_PROGRAM_IMG_URL,
  DARK_TOP,
  DATE,
  LIGHT_TOP,
  PAGES,
  TEXT,
  VOTE_IMG_URL,
} from "../../../constants";
import { resetState, setPage } from "../../../redux/actions/appActions";
import { getCityProgramsBody, getCityProgramsImage } from "../api";
import "./styles.scss";
import { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";
import CityProgramsImage from "./CityProgramsImage";

export default function CityProgramsView() {
  const [data, setData] = useState({ questions: [], fileIds: [] });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getCityProgramsBody(id)
      .then(async (res) => {
        let { createdDate, lightAttachmentsRu } = res.data.data;
        setData(res.data.data);
        let pageData = {
          topType: !isEmpty(lightAttachmentsRu) ? DARK_TOP : LIGHT_TOP,
          title: locale(res.data.data, "title"),
          desc: toShowDate(createdDate, true),
          descType: TEXT,
          route: [
            { name: t("main"), url: "/" },
            { name: t("city_programs"), url: PAGES.cityPrograms },
            { name: locale(res.data.data, "title") },
          ],
        };
        if (!isEmpty(lightAttachmentsRu))
          await getImage(lightAttachmentsRu[0]?.id);
        dispatch(setPage(pageData));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      dispatch(resetState("page"));
    };
  }, []);

  function getImage(imageId) {
    // getCityProgramsImage(imageId)
    //   .then((res) => {
    //     dispatch(
    //       setPage({
    //         topImage: "data:image/png;base64," + res.data.data.content,
    //       })
    //     );
    //   })
    //   .catch((err) => {});
    dispatch(
      setPage({
        topImage: CITY_PROGRAM_IMG_URL + imageId,
      })
    );
  }

  return (
    <Template loading={loading}>
      <div className="city_novelty__view">
        {!isEmpty(data.lightAttachmentsRu) && (
          <SRLWrapper>
            {data.lightAttachmentsRu.map((i, index) => (
              <CityProgramsImage key={index} id={i.id} />
            ))}
          </SRLWrapper>
        )}
        {locale(data, "description")}
      </div>
    </Template>
  );
}
