import React from "react";

//Карточка данных о городе
export default function InfoCard({ icon, title, number, unit, type }) {
  return (
    <div className={"info_card " + type}>
      <div className="icon">{icon}</div>
      <div className="info">
        <h3>{title}</h3>
        <div className="count">
          {number}
          <span>{unit}</span>
        </div>
      </div>
    </div>
  );
}
