import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import { ERROR, INFO, SUCCESS, WARNING } from "../../constants";

export default function AlertR({ type = ERROR, show = false, label = "" }) {
  const classNames = {
      [INFO]: "alert__info",
      [SUCCESS]: "alert__success",
      [ERROR]: "alert__error",
      [WARNING]: "alert__warning",
    },
    icons = {
      [INFO]: "info",
      [SUCCESS]: "alert__success",
      [ERROR]: <InfoOutlinedIcon />,
      [WARNING]: "alert__warning",
    };

  if (!show) return <></>;

  return (
    <div className={"alert " + classNames[type]}>
      {icons[type]}
      <span>{label}</span>
    </div>
  );
}
