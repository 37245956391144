import instance from "../../../api/api";
import { handleError } from "../../../api/helpers";
import { getRequestHeader } from "../../../components/helpers";

//Сохранение даных
export const doRegister = (params) => {
  return instance(false)
    .post(`/Authentication/Register`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

//ПРоверка телефона
export const isRegistered = (params) => {
  return instance(false)
    .post(`/Authentication/IsRegistered`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
