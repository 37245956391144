import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ShareBtns from "../../../components/design/ShareBtns";
import { ButtonR } from "../../../components/form";
import { setShowModal } from "../../../redux/actions/appActions";

export default function VotesModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { page } = useSelector((state) => state.app);
  const [showShare, setShowShare] = useState(false);

  const handleShare = () => {
    setShowShare(true);
  };

  return (
    <div className="def_modal votes_modal">
      {showShare ? (
        <ShareBtns
          title={page.title}
          description=""
          url={window.location.href}
        />
      ) : (
        <>
          <h3>{t("v_m_title")} </h3>
          <p>{t("v_m_desc")}</p>
        </>
      )}
      <div className="d-flex">
        {!showShare && (
          <ButtonR variant="contained" color="primary" onClick={handleShare}>
            {t("share")}
          </ButtonR>
        )}
        <ButtonR
          variant="contained-light"
          color="secondary"
          onClick={() => window.location.reload()}
        >
          {t("close")}
        </ButtonR>
      </div>
    </div>
  );
}
