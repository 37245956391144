import { ThumbDownAltOutlined, ThumbUpAltOutlined } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { SRLWrapper } from "simple-react-lightbox";
import { ButtonR } from "../../../components/form";
import {
  getRequestHeader,
  isEmpty,
  locale,
  toShowDate,
} from "../../../components/helpers";
import Template from "../../../components/template";
import {
  DARK_TOP,
  DATE,
  ERROR,
  LIGHT_TOP,
  PAGES,
  VOTES_INFO_MODAL,
} from "../../../constants";
import {
  resetState,
  setPage,
  setShowModal,
} from "../../../redux/actions/appActions";
import {
  getSuggestionBody,
  getSuggestionImage,
  submitSuggestionRating,
} from "../api";
import CommentsTab from "./CommentsTab";
import "./styles.scss";
import SuggestionImage from "./SuggestionImage";

export default function SuggestionView() {
  const [data, setData] = useState({ questions: [], attachmentIds: [] });
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.app);

  useEffect(() => {
    getData(true);
    return () => {
      dispatch(resetState("page"));
    };
  }, [id]);

  function getData(showMsg = false) {
    const params = {
      SuggestionId: id,
      // ...getRequestHeader(),
      header: null,
    };
    getSuggestionBody(params)
      .then(async (res) => {
        let { startDate, endDate, attachmentIds, ...resData } = res.data.data;
        setData(res.data.data);
        let pageData = {
          topType: !isEmpty(attachmentIds) ? DARK_TOP : LIGHT_TOP,
          title: locale(resData, "title"),
          desc: `${toShowDate(startDate)} - ${toShowDate(endDate)}`,
          descType: DATE,
          route: [
            { name: t("main"), url: "/" },
            { name: t("talk"), url: PAGES.suggestionList },
            { name: locale(resData, "title") },
          ],
        };
        if (!isEmpty(attachmentIds)) await getImage(attachmentIds[0]);
        dispatch(setPage(pageData));
        setLoading(false);
        //Redirect to login
        if (!res.data.isSuccess) history.push(PAGES.login);
        if (res?.data?.data?.isActive && showMsg)
          dispatch(
            setShowModal(true, VOTES_INFO_MODAL, {
              title: t("only_one_suggestion"),
              desc: "",
            })
          );
      })
      .catch((err) => {
        enqueueSnackbar(t("talkId"), {
          variant: ERROR,
        });
        history.push(PAGES.suggestionList);
        setLoading(false);
      });
  }

  function getImage(imageId) {
    getSuggestionImage(imageId)
      .then((res) => {
        dispatch(
          setPage({
            topImage: "data:image/png;base64," + res.data.data.content,
          })
        );
      })
      .catch((err) => {});
  }

  function handleLike(isLike = false) {
    if (!user?.auth) {
      history.push(PAGES.login + "/suggestion_" + id);
      return;
    }

    const params = {
      SuggestionId: id,
      IsLike: isLike,
      ...getRequestHeader(),
    };
    submitSuggestionRating(params)
      .then((res) => {
        if (res.data.isSuccess) getData();
        else {
          if (data.isActive) {
            enqueueSnackbar(t("voted"), {
              variant: ERROR,
            });
          } else
            enqueueSnackbar(t("inactive"), {
              variant: ERROR,
            });
        }
      })
      .catch((err) => {});
  }

  function scrollToComments(isComment = false) {
    let tabNumber = 0;
    if (!isComment) tabNumber = 1;
    setTab(tabNumber);

    setTimeout(() => {
      const commentsTab = document.getElementById("comments-form-" + tabNumber);
      commentsTab.scrollIntoView({ block: "center", behavior: "smooth" });
    }, 0);
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Template loading={loading}>
      <div className="suggestion layout">
        <div className="suggestion__top_actions d-flex justify-content-between">
          <div>
            <ButtonR
              startIcon={<ThumbUpAltOutlined style={{ color: "#2BB472" }} />}
              variant="contained-light"
              color="secondary"
              onClick={() => handleLike(true)}
            >
              {data.likes}
            </ButtonR>
            <ButtonR
              startIcon={<ThumbDownAltOutlined style={{ color: "#E84B55" }} />}
              variant="contained-light"
              color="secondary"
              onClick={() => handleLike(false)}
            >
              {data.dislikes}
            </ButtonR>
          </div>
          <div>
            {data.isActive && (
              <>
                <ButtonR
                  variant="contained-light"
                  color="secondary"
                  style={{ color: "#2BB472" }}
                  onClick={() => scrollToComments(true)}
                >
                  {t("send_comm")}
                </ButtonR>
                <ButtonR
                  variant="contained-light"
                  color="secondary"
                  style={{ color: "#2BB472" }}
                  onClick={() => scrollToComments(false)}
                >
                  {t("ask")}
                </ButtonR>
              </>
            )}
          </div>
        </div>
        <div className="suggestion__info">
          <p className="label">Описание:</p>
          <p className="info">{locale(data, "description")}</p>
          {!isEmpty(data.attachmentIds) && (
            <>
              <p className="label">{t("image")}</p>
              <SRLWrapper>
                {data.attachmentIds.map((i, index) => (
                  <SuggestionImage key={index} id={i} />
                ))}
              </SRLWrapper>
            </>
          )}
        </div>
        <CommentsTab
          isActive={data.isActive}
          tab={tab}
          handleChangeTab={handleChangeTab}
        />
      </div>
    </Template>
  );
}
