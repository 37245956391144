import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import { getRequestHeader } from "../../../components/helpers";
import Template from "../../../components/template";
import { LIGHT_TOP, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getStopCovidList } from "../api";
import CovidStatistics from "./CovidStatistics";
import StopCovid from "./StopCovid";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function StopCovidList() {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️🚫 " + `${t("covid")}`,
    desc: `${t("stop")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("covid") }],
  };

  console.log("language", language);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    getStopCovidList()
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, [language]);

  return (
    <Template loading={loading}>
      <EmptyOrShow loading={loading}>
        <CovidStatistics />
        <StopCovid type={value} list={list} />
      </EmptyOrShow>
    </Template>
  );
}
