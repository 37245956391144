import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Template from "../../components/template";
import { LIGHT_TOP, TEXT } from "../../constants";
import { setPage } from "../../redux/actions/appActions";
import "./styles.scss";

import { ReactComponent as Icon1 } from "../../assets/images/about-city/icon1.svg";
import { ReactComponent as Icon2 } from "../../assets/images/about-city/icon2.svg";
import { ReactComponent as Icon3 } from "../../assets/images/about-city/icon3.svg";
import { ReactComponent as Icon4 } from "../../assets/images/about-city/icon4.svg";

import { ReactComponent as Icon5 } from "../../assets/images/about-city/icon5.svg";
import { ReactComponent as Icon6 } from "../../assets/images/about-city/icon6.svg";
import { ReactComponent as Icon7 } from "../../assets/images/about-city/icon7.svg";
import { ReactComponent as Icon8 } from "../../assets/images/about-city/icon8.svg";

import { ReactComponent as Icon9 } from "../../assets/images/about-city/icon9.svg";
import { ReactComponent as Icon10 } from "../../assets/images/about-city/icon10.svg";

import { ReactComponent as Icon11 } from "../../assets/images/about-city/icon11.svg";
import { ReactComponent as Icon12 } from "../../assets/images/about-city/icon12.svg";
import { ReactComponent as Icon13 } from "../../assets/images/about-city/icon13.svg";

import { ReactComponent as Icon14 } from "../../assets/images/about-city/icon14.svg";
import { ReactComponent as Icon15 } from "../../assets/images/about-city/icon15.svg";
import { ReactComponent as Icon16 } from "../../assets/images/about-city/icon16.svg";
import { ReactComponent as Icon17 } from "../../assets/images/about-city/icon17.svg";
import { ReactComponent as Icon18 } from "../../assets/images/about-city/icon18.svg";
import { ReactComponent as Icon19 } from "../../assets/images/about-city/icon19.svg";

import { ReactComponent as Icon20 } from "../../assets/images/about-city/icon20.svg";

import { ReactComponent as Icon21 } from "../../assets/images/about-city/icon21.svg";
import { ReactComponent as Icon22 } from "../../assets/images/about-city/icon22.svg";
import { ReactComponent as Icon23 } from "../../assets/images/about-city/icon23.svg";
import { ReactComponent as Icon24 } from "../../assets/images/about-city/icon24.svg";
import { ReactComponent as Icon25 } from "../../assets/images/about-city/icon25.svg";
import { ReactComponent as Icon26 } from "../../assets/images/about-city/icon26.svg";

import { ReactComponent as Icon27 } from "../../assets/images/about-city/icon27.svg";
import { ReactComponent as Icon28 } from "../../assets/images/about-city/icon28.svg";
import { ReactComponent as Icon29 } from "../../assets/images/about-city/icon29.svg";
import { ReactComponent as Icon30 } from "../../assets/images/about-city/icon30.svg";

import { ReactComponent as Icon31 } from "../../assets/images/about-city/icon31.svg";
import { ReactComponent as Icon32 } from "../../assets/images/about-city/icon32.svg";

import { ReactComponent as Icon33 } from "../../assets/images/about-city/icon33.svg";
import { ReactComponent as Icon34 } from "../../assets/images/about-city/icon34.svg";
import { ReactComponent as Icon35 } from "../../assets/images/about-city/icon35.svg";
import { ReactComponent as Icon36 } from "../../assets/images/about-city/icon36.svg";

import { ReactComponent as Icon37 } from "../../assets/images/about-city/icon37.svg";
import { ReactComponent as Icon38 } from "../../assets/images/about-city/icon38.svg";
import { ReactComponent as Icon39 } from "../../assets/images/about-city/icon39.svg";
import { ReactComponent as Icon40 } from "../../assets/images/about-city/icon40.svg";
import { ReactComponent as Icon41 } from "../../assets/images/about-city/icon41.svg";

import { ReactComponent as Icon42 } from "../../assets/images/about-city/icon42.svg";
import { ReactComponent as Icon43 } from "../../assets/images/about-city/icon43.svg";
import { ReactComponent as Icon44 } from "../../assets/images/about-city/icon44.svg";

import { ReactComponent as Health1 } from "../../assets/images/about-city/health1.svg";
import { ReactComponent as Health2 } from "../../assets/images/about-city/health2.svg";
import { ReactComponent as Health3 } from "../../assets/images/about-city/health3.svg";
import { ReactComponent as Health4 } from "../../assets/images/about-city/health4.svg";
import { ReactComponent as Health5 } from "../../assets/images/about-city/health5.svg";

import InfoCard from "./InfoCard";
import { getAllCityInfo } from "./api";
import { cloneDeep } from "lodash";
import EmptyOrShow from "../../components/design/EmptyOrShow";
import { useTranslation } from "react-i18next";
import { locale } from "../../components/helpers";

const TYPES = {
  green: "green",
  greenFull: "green_full",
  white: "white",
  whiteFull: "white_full",

  //new types
  row2: "row2",
  row3: "row3",
  row4: "row4",
};

export default function AboutCity() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "📍 " + `${t("base")}`,
    desc: `${t("infograph")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("description") }],
  };

  const DATA = [
    {
      title: t("people"),
      cards: [
        {
          id: 1,
          title: t("amount"),
          icon: <Icon1 />,
          count: "1 955",
          unit: t("people_count"),
          type: TYPES.row4,
        },
        {
          id: 2,
          title: t("terr"),
          icon: <Icon2 />,
          count: "683,5",
          unit: t("terr_count"),
          type: TYPES.row4,
        },
        {
          id: 3,
          title: t("district"),
          icon: <Icon3 />,
          count: "8",
          unit: "",
          type: TYPES.row4,
        },
        {
          id: 4,
          title: t("life"),
          icon: <Icon4 />,
          count: "75,45",
          unit: t("life_count"),
          type: TYPES.row4,
        },
      ],
    },
    {
      title: "Экономика",
      cards: [
        {
          id: 5,
          title: t("product"),
          icon: <Icon5 />,
          count: "13,5",
          unit: t("product_count"),
          type: TYPES.row4,
        },
        {
          id: 6,
          title: t("wrps"),
          icon: <Icon6 />,
          count: "7,2",
          unit: t("wrps_count"),
          type: TYPES.row4,
        },
        {
          id: 7,
          title: t("invest"),
          icon: <Icon7 />,
          count: "820,4",
          unit: t("invest_count"),
          type: TYPES.row4,
        },
        {
          id: 8,
          title: t("msbs"),
          icon: <Icon8 />,
          count: "190",
          unit: t("count"),
          type: TYPES.row4,
        },
      ],
    },
    {
      title: t("busy"),
      cards: [
        {
          id: 10,
          title: t("jobless"),
          icon: <Icon9 />,
          count: "5,1",
          unit: "%",
          type: TYPES.row3,
        },
        {
          id: 11,
          title: t("salary"),
          icon: <Icon10 />,
          count: "224",
          unit: t("salary_count"),
          type: TYPES.row3,
        },
        {
          id: 12,
          title: t("busy_people"),
          icon: <Icon1 />,
          count: "936,5",
          unit: t("count"),
          type: TYPES.row3,
        },
      ],
    },
    {
      title: t("road"),
      cards: [
        {
          id: 13,
          title: t("length"),
          icon: <Icon11 />,
          count: "3096",
          unit: "км",
          type: TYPES.row4,
        },
        {
          id: 15,
          title: t("metro"),
          icon: <Icon12 />,
          count: "9",
          unit: "",
          type: TYPES.row4,
        },
        {
          id: 16,
          title: t("bus"),
          icon: <Icon6 />,
          count: "137",
          unit: "",
          type: TYPES.row4,
        },
        {
          id: 14,
          title: t("avto"),
          icon: <Icon13 />,
          count: "536 313",
          unit: t("avto_count"),
          type: TYPES.row4,
        },
      ],
    },
    {
      title: t("build"),
      cards: [
        {
          id: 17,
          title: t("building"),
          icon: <Icon14 />,
          count: "2 102,5",
          unit: t("building_count"),
          type: TYPES.row3,
        },
        {
          id: 18,
          title: t("fond"),
          icon: <Icon15 />,
          count: "45,7",
          unit: t("fond_count"),
          type: TYPES.row3,
        },
        {
          id: 20,
          title: t("fond_water"),
          icon: <Icon16 />,
          count: "100",
          unit: "%",
          type: TYPES.row3,
        },
        {
          id: 21,
          title: t("fond_kanal"),
          icon: <Icon17 />,
          count: "2 102,5",
          unit: t("building_count"),
          type: TYPES.row3,
        },
        {
          id: 22,
          title: t("fond_hot"),
          icon: <Icon18 />,
          count: "71,9",
          unit: "%",
          type: TYPES.row3,
        },
        {
          id: 23,
          title: t("fond_hotwater"),
          icon: <Icon19 />,
          count: "69,4",
          unit: "%",
          type: TYPES.row3,
        },
      ],
    },
    {
      title: t("sciences"),
      cards: [
        {
          id: 25,
          title: t("emp"),
          icon: <Icon10 />,
          count: "8,9",
          unit: t("busy_count"),
          type: TYPES.row2,
        },
        {
          id: 24,
          title: t("science"),
          icon: <Icon20 />,
          count: "138",
          unit: "",
          type: TYPES.row2,
        },
      ],
    },
    {
      title: t("edu"),
      cards: [
        {
          id: 27,
          title: t("pre"),
          icon: <Icon21 />,
          count: "869",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 28,
          title: t("pre_child"),
          icon: <Icon22 />,
          count: "69,3",
          unit: t("pre_child_count"),
          type: TYPES.row3,
        },
        {
          id: 29,
          title: t("school"),
          icon: <Icon23 />,
          count: "290",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 30,
          title: t("school_child"),
          icon: <Icon24 />,
          count: "286,7",
          unit: t("count"),
          type: TYPES.row3,
        },
        {
          id: 31,
          title: t("univer"),
          icon: <Icon25 />,
          count: "41",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 32,
          title: t("univer_child"),
          icon: <Icon26 />,
          count: "162,7",
          unit: t("count"),
          type: TYPES.row3,
        },
      ],
    },
    {
      title: t("health"),
      cards: [
        {
          id: 91,
          title: t("health1"),
          icon: <Health1 />,
          count: "92",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 92,
          title: t("health2"),
          icon: <Health2 />,
          count: "13,1",
          unit: t("count"),
          type: TYPES.row3,
        },
        {
          id: 93,
          title: t("health3"),
          icon: <Health3 />,
          count: "18,9",
          unit: t("count"),
          type: TYPES.row3,
        },
        {
          id: 94,
          title: t("health4"),
          icon: <Health4 />,
          count: "10,8",
          unit: t("count"),
          type: TYPES.row2,
        },
        {
          id: 95,
          title: t("health5"),
          icon: <Health5 />,
          count: "6,5",
          unit: "",
          type: TYPES.row2,
        },
      ],
    },
    {
      title: t("social"),
      cards: [
        {
          id: 38,
          title: t("vov"),
          icon: <Icon27 />,
          count: "140",
          unit: "",
          type: TYPES.row2,
        },
        {
          id: 39,
          title: t("invalide"),
          icon: <Icon28 />,
          count: "53,7",
          unit: t("count"),
          type: TYPES.row2,
        },
        {
          id: 40,
          title: t("invalide_children"),
          icon: <Icon28 />,
          count: "7",
          unit: t("count"),
          type: TYPES.row3,
        },
        {
          id: 41,
          title: t("pension"),
          icon: <Icon29 />,
          count: "230",
          unit: t("count"),
          type: TYPES.row3,
        },
        {
          id: 42,
          title: t("payment"),
          icon: <Icon30 />,
          count: "58,7",
          unit: t("count"),
          type: TYPES.row3,
        },
      ],
    },
    {
      title: t("safety"),
      cards: [
        {
          id: 43,
          title: t("sergek"),
          icon: <Icon31 />,
          count: "400",
          unit: "",
          type: TYPES.row2,
        },
        {
          id: 45,
          title: t("crime"),
          icon: <Icon32 />,
          count: "289",
          unit: (
            <span>
              {t("crime_count")} <span className="big">10</span>{" "}
              {t("crime_count2")}
            </span>
          ),
          type: TYPES.row2,
        },
      ],
    },
    {
      title: "Туризм",
      cards: [
        {
          id: 46,
          title: t("hotel"),
          icon: <Icon33 />,
          count: "346 123",
          unit: "",
          type: TYPES.row2,
        },
        {
          id: 47,
          title: t("room"),
          icon: <Icon34 />,
          count: "12",
          unit: t("count"),
          type: TYPES.row2,
        },
        {
          id: 48,
          title: t("tourist"),
          icon: <Icon35 />,
          count: "1,3",
          unit: t("tourist_count"),
          type: TYPES.row2,
        },
        {
          id: 49,
          title: t("foreign"),
          icon: <Icon36 />,
          count: "436",
          unit: t("count"),
          type: TYPES.row2,
        },
      ],
    },
    {
      title: t("cult"),
      cards: [
        {
          id: 51,
          title: t("lib"),
          icon: <Icon37 />,
          count: "31",
          unit: "",
          type: TYPES.row2,
        },
        {
          id: 50,
          title: t("museum"),
          icon: <Icon38 />,
          count: "17",
          unit: "",
          type: TYPES.row2,
        },
        {
          id: 52,
          title: t("theatre"),
          icon: <Icon39 />,
          count: "18",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 54,
          title: t("concert"),
          icon: <Icon40 />,
          count: "7",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 53,
          title: t("park"),
          icon: <Icon41 />,
          count: "15",
          unit: "",
          type: TYPES.row3,
        },
      ],
    },
    {
      title: "Спорт",
      cards: [
        {
          id: 56,
          title: t("sport"),
          icon: <Icon42 />,
          count: "2,7",
          unit: t("count"),
          type: TYPES.row3,
        },
        {
          id: 57,
          title: t("club"),
          icon: <Icon43 />,
          count: "8",
          unit: "",
          type: TYPES.row3,
        },
        {
          id: 58,
          title: t("type"),
          icon: <Icon44 />,
          count: "63",
          unit: "",
          type: TYPES.row3,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    //Получение инфо о городе
  }, []);

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    getAllCityInfo().then((res) => {
      let newData = cloneDeep(DATA);
      let apiData = res.data.data;
      newData.forEach((i) => {
        i.cards.forEach((j) => {
          let obj = apiData.filter((k) => k.id === j.id)[0];
          if (obj) {
            j.number = getNumber(locale(obj, "value"));
          }
        });
      });
      setData(newData);
      setLoading(false);
    });
  }, [language]);

  //фильтрация номера
  function getNumber(str = "") {
    let regex = /[\d|,|.|e|E|\+]+/g;
    let matches = str.trim().match(regex);
    return matches.join(" ");
  }

  return (
    <Template>
      <div className="about_city layout">
        <EmptyOrShow loading={loading}>
          {data.map((i, index) => (
            <div key={index}>
              <h3>{i.title}</h3>
              <div className="d-flex flex-wrap justify-content-between">
                {i.cards.map((j, ind) => (
                  <InfoCard key={ind} {...j} />
                ))}
              </div>
            </div>
          ))}
        </EmptyOrShow>
      </div>
    </Template>
  );
}
