import { makeStyles } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DARK_TOP, DATE, LIGHT_TOP, TEXT } from "../../../constants";
import { getVoteImage } from "../../../pages/votes/api";
import { setPage } from "../../../redux/actions/appActions";
import { DividerR } from "../../design";
import { ButtonR } from "../../form";
import RouteLinks from "./RouteLinks";

// const useStyles = makeStyles({
//   backgroundImage: {
//     backgroundImage: ({ topImage }) => `url(${topImage}) !important`,
//   },
// });

export default function Top() {
  const { page } = useSelector((state) => state.app);
  const {
    topType,
    topImage = "",
    title = "",
    desc = "",
    descType,
    moreBtn = {},
  } = page;
  // const { backgroundImage } = useStyles({ topImage });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getTopClass = () => {
    switch (topType) {
      case LIGHT_TOP:
        return "top top_light";
      case DARK_TOP:
        return "top top_dark";
      default:
        break;
    }
  };

  const handleClickMore = () =>
    dispatch(
      setPage({
        ...page,
        moreBtn: {
          ...moreBtn,
          descShow: !moreBtn.descShow,
        },
      })
    );

  return (
    <div
      className={getTopClass()}
      style={
        topType === DARK_TOP ? { backgroundImage: `url(${topImage})` } : {}
      }
    >
      {topType === DARK_TOP && <div className="top__over"></div>}
      <div className="top__inner">
        <RouteLinks />
        <div className="top__title">{title}️</div>
        <DividerR />
        <TopDesc type={descType} desc={desc} />
        {moreBtn?.show && (
          <ButtonR
            variant="contained"
            color="primary"
            onClick={handleClickMore}
            style={{ marginTop: 20 }}
          >
            {t("more")}
          </ButtonR>
        )}
      </div>
    </div>
  );
}

function TopDesc({ type, desc }) {
  switch (type) {
    case TEXT:
      return <div className="top__desc">{desc}</div>;
    case DATE:
      return (
        <div className="top__desc d-flex align-items-center">
          <CalendarToday />
          {desc}
        </div>
      );
    default:
      return <></>;
      break;
  }
}
