import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { isEmpty, locale, toShowDate } from "../../../components/helpers";
import Template from "../../../components/template";
import { DARK_TOP, DATE, LIGHT_TOP, PAGES } from "../../../constants";
import { resetState, setPage } from "../../../redux/actions/appActions";
import { getCityNoveltyBody, getCityNoveltyImage } from "../api";
import CityNoveltyImage from "./CityNoveltyImage";
import "./styles.scss";
import { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";
export default function CityNoveltyView() {
  const [data, setData] = useState({ questions: [], fileIds: [] });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    getCityNoveltyBody(id)
      .then(async (res) => {
        let { publishDate, fileIds } = res.data.data;
        setData(res.data.data);
        let pageData = {
          topType: !isEmpty(fileIds) ? DARK_TOP : LIGHT_TOP,
          title: locale(res.data.data, "title"),
          desc: toShowDate(publishDate, true),
          descType: DATE,
          route: [
            { name: t("main"), url: "/" },
            { name: t("news"), url: PAGES.cityNoveltyList },
            { name: locale(res.data.data, "title") },
          ],
        };
        if (!isEmpty(fileIds)) await getImage(fileIds[0]);
        dispatch(setPage(pageData));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      dispatch(resetState("page"));
    };
  }, []);

  function getImage(imageId) {
    getCityNoveltyImage(imageId)
      .then((res) => {
        dispatch(
          setPage({
            topImage: "data:image/png;base64," + res.data.data.content,
          })
        );
      })
      .catch((err) => {});
  }

  return (
    <Template loading={loading}>
      <div className="city_novelty__view">
        {!isEmpty(data.fileIds) && (
          <SRLWrapper>
            {data.fileIds.map((i, index) => (
              <CityNoveltyImage key={index} id={i} />
            ))}
          </SRLWrapper>
        )}

        {locale(data, "description")}
      </div>
    </Template>
  );
}
