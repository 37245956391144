import instance from "../../api/api";
import { handleError } from "../../api/helpers";

//Получение данных о городе
export const getAllCityInfo = (params) => {
  return instance(false)
    .post(`/CityInfo/GetAllCityInfo`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
