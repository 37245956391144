import React from "react";
import Top from "./Top";

export default function Main({ children }) {
  return (
    <div className="main">
      <Top />
      {children}
    </div>
  );
}
