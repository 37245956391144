import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Thumb from "../../../components/design/list/Thumb";
import { getShortTxt, locale } from "../../../components/helpers";
import { BONUS_MODAL } from "../../../constants";
import { setShowModal } from "../../../redux/actions/appActions";
import { getBonusChildImage } from "../api";

export default function BonusCard(props) {
  let {
    id,
    imageId,
    inStockQuantity = "",
    takenQuantity,
    price,
    types,
  } = props;

  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    //Получение изображении
    if (imageId)
      getBonusChildImage(imageId)
        .then((res) => {
          setImage("data:image/png;base64," + res.data.data.content);
        })
        .catch((err) => {});
  }, []);

  function handleClick(params) {
    dispatch(setShowModal(true, BONUS_MODAL, { image, ...props }));
  }

  return (
    <div className="list_card" onClick={handleClick}>
      <Thumb image={image} />
      <div className="list_card__info">
        <h3 className="wrap">{getShortTxt(locale(props, "title"), 140)}</h3>
        <div className="d-flex">
          <div>
            {t("bonus_exist")}:{" "}
            <span>
              {inStockQuantity
                ? inStockQuantity
                : types.map((i, index) => (
                    <span
                      key={index}
                      style={{ display: "block", lineHeight: "24px" }}
                    >
                      <span>
                        {locale(i, "title")} - {i.inStockQuantity},{" "}
                      </span>
                    </span>
                  ))}
            </span>
          </div>
          <div>
            {t("bonus_got")}:{" "}
            <span>
              {takenQuantity
                ? takenQuantity
                : types.map((i, index) => (
                    <span
                      key={index}
                      style={{ display: "block", lineHeight: "24px" }}
                    >
                      <span>
                        {locale(i, "title")} - {i.takenQuantity},{" "}
                      </span>
                    </span>
                  ))}
            </span>
          </div>
        </div>
      </div>
      <div className="list_card__actions">
        <p>{t("price")}:</p>
        <h3>
          {price ? (
            price
          ) : (
            <span className="price_list">
              {types.map((i, index) => (
                <span key={index}>
                  <span> {locale(i, "title")} </span>
                  <span className="green"> {i.price} </span>
                </span>
              ))}
            </span>
          )}
        </h3>
      </div>
    </div>
  );
}
