import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getBonusList = () => {
  return instance(false)
    .get(`/Bonus/GetBonusShopCategories`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getBonusBody = (id) => {
  return instance(false)
    .get(`/Bonus/GetBonusShopItems?categoryId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getBonusImage = (id) => {
  return instance(false)
    .get(`/Bonus/GetBonusShopCategoryImage?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getBonusChildImage = (id) => {
  return instance(false)
    .get(`/Bonus/GetBonusShopItemImage?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getShopItem = (params) => {
  return instance(false)
    .post(`/Bonus/GetShopItem`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
