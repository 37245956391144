import logo from "./logo.svg";
import "./assets/css/global.scss";
import "./assets/css/desktop.scss";
import "./assets/css/mobile.scss";
import MainRouter from "./router/MainRouter";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import SimpleReactLightbox from "simple-react-lightbox";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_ru from "./translations/ru/common.json";
import common_kz from "./translations/kz/common.json";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LanguageDetector from "i18next-browser-languagedetector";

const DETECTION_OPTIONS = {
  order: ["localStorage"],
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ru: {
        translation: common_ru,
      },
      kz: {
        translation: common_kz,
      },
    },
    lng: "ru",
    fallbackLng: "ru",
    detection: DETECTION_OPTIONS,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2BB472",
    },
    secondary: {
      main: "#F8FAF9",
    },
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: "#2BB472",
      },
    },
    MuiButtonContainedSecondary: {
      text: {
        color: "#161A17",
      },
    },
  },
});

function App() {
  return (
    <SimpleReactLightbox>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MainRouter />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </SimpleReactLightbox>
  );
}

export default App;
