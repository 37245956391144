import { ArrowForwardOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Thumb from "../../../components/design/list/Thumb";
import { ButtonR } from "../../../components/form";
import { getShortTxt, locale } from "../../../components/helpers";
import { PAGES, STOP_COVID_IMG_URL } from "../../../constants";

const ACTIVE_TYPE = 0;

export default function CityNewsCard({
  id,
  imageId,
  createdDate = "",
  type,
  ...props
}) {
  const history = useHistory();

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (imageId) setImage(STOP_COVID_IMG_URL + imageId);
    // getStopCovidImage(imageId)
    //   .then((res) => {
    //     setImage("data:image/png;base64," + res.data.data.content);
    //   })
    //   .catch((err) => {});
  }, []);

  function handleClick(params) {
    window.stop();
    history.push(`${PAGES.stopCovidView}/${id}`);
  }

  return (
    <div className="list_card" onClick={handleClick}>
      <Thumb image={image} />
      <div className="list_card__info">
        <h3>{getShortTxt(locale(props, "title"), 140)}</h3>
        {/* <div className="dates">
          <CalendarToday />
          <span>{toShowDate(createdDate, true)}</span>
        </div> */}
      </div>
      <div className="list_card__actions">
        <ButtonR
          variant={"outlined"}
          color="secondary"
          startIcon={<ArrowForwardOutlined />}
        ></ButtonR>
      </div>
    </div>
  );
}
