import React from "react";

import { Animated } from "react-animated-css";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setShowMenu } from "../../../redux/actions/appActions";
import { ButtonR } from "../../form";
import { PAGES } from "../../../constants";
import { useHistory } from "react-router";
import { ClickAwayListener } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function MenuAnimated() {
  const { menu } = useSelector((state) => state.app);
  const { show } = menu;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const LINKS = [
    {
      title: t("news"),
      url: PAGES.cityNoveltyList,
    },
    {
      title: t("vote"),
      url: PAGES.votesList,
    },
    {
      title: t("talk"),
      url: PAGES.suggestionList,
    },
    {
      title: t("covid"),
      url: PAGES.stopCovidList,
    },
    {
      title: t("call"),
      url: PAGES.emergencyCallList,
    },
    /* {
      title: t("history"),
      url: PAGES.historyList,
    },*/
    {
      title: t("budjet"),
      url: "https://budget.open-almaty.kz/",
      outside: true,
    },
    {
      title: t("profile"),
      url: PAGES.profile,
    },
    {
      title: t("programms"),
      url: PAGES.cityPrograms,
    },
    {
      title: t("rating"),
      url: PAGES.cityAchievements,
    },
    {
      title: t("description"),
      url: PAGES.aboutCity,
    },
    {
      title: t("shop"),
      url: PAGES.bonusList,
    },
    {
      title: t("about"),
      url: PAGES.about,
    },
  ];

  function hideMenu(params) {
    dispatch(setShowMenu(false));
  }

  function handleClickLink(url, outside = false) {
    dispatch(setShowMenu(false));
    if (outside) window.location.href = url;
    else history.push(url);
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={hideMenu}
    >
      <Animated
        animationIn="fadeInDown"
        animationOut="fadeOutUp"
        animationInDuration={500}
        animationOutDuration={200}
        isVisible={show}
        className="menu__wrapper"
      >
        {show && (
          <div className="menu__wrapper__content">
            <div className="menu__btn" onClick={hideMenu}>
              <Close />
              <span>{t("menu")}</span>
            </div>
            <div className="links">
              {LINKS.map((i, index) => (
                <ButtonR
                  variant="default"
                  color="primary"
                  key={index}
                  onClick={() => handleClickLink(i.url, i.outside)}
                >
                  {i.title}
                </ButtonR>
              ))}
            </div>
          </div>
        )}
      </Animated>
    </ClickAwayListener>
  );
}
