import { AccountCircle } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getProfilePhoto, updateProfilePhoto } from "./api";
import { ReactComponent as ProfileArrow } from "../../assets/images/profile-arrow.svg";
import UploadFile from "../../components/form/UploadFile";
import { ButtonR } from "../../components/form";
import {
  getRequestHeader,
  toBase64,
  validateFileFormat,
} from "../../components/helpers";
import Thumb from "../../components/design/list/Thumb";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ERROR } from "../../constants";

export default function ProfilePhoto({}) {
  const [image, setImage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(async () => {
    getProfilePhoto(getRequestHeader())
      .then((res) => {
        if (res.data.data)
          setImage("data:image/png;base64," + res.data.data.content);
      })
      .catch((err) => {});
  }, []);

  async function handleChange(e) {
    let file = e.target.files[0];
    if (file?.size > 5000000) {
      enqueueSnackbar(t("size_photo"), {
        variant: ERROR,
      });
      return;
    }
    if (!validateFileFormat([".jpg", ".jpeg", ".png"], file?.name)) {
      enqueueSnackbar(t("profile_photo_err"), {
        variant: ERROR,
      });
      return;
    }

    let base64 = await toBase64(file);
    setImage(base64);

    const params = {
      ...getRequestHeader(),
      photo: {
        content: base64.replace("data:image/jpeg;base64,", ""),
        name: "photo",
        mimeType: file.type,
      },
    };

    updateProfilePhoto(params)
      .then((res) => {
        if (res.data.data) setImage(res.data.data.content);
      })
      .catch((err) => {});
  }

  return (
    <div className="profile__photo">
      <h3>{t("photo")}</h3>
      <div className="profile_settings__thumb">
        <div className="image">
          <Thumb image={image} />
        </div>
        <ProfileArrow />
        <div>
          <p>{t("current_photo")}</p>
          <UploadFile accept="image/*" onChange={handleChange}>
            <ButtonR
              variant="contained"
              color="primary"
              style={{ zIndex: "-1" }}
            >
              {t("load_photo")}
            </ButtonR>
          </UploadFile>
          <span>{t("size_photo")}</span>
        </div>
      </div>
    </div>
  );
}
