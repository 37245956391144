import React, { useEffect, useState } from "react";
import StopCovidCard from "./StopCovidCard";

export default function StopCovid({ type, list = [] }) {
  return (
    <div className="layout stop_covid">
      <h3>Статьи:</h3>
      {list.map((i, index) => (
        <StopCovidCard key={index} {...i} type={type} />
      ))}
      {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
    </div>
  );
}
