import { ArrowForwardOutlined, CalendarToday } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { ReactComponent as SuggestionIcon } from "../../../assets/images/suggestion-icon.svg";
import { ReactComponent as VoteIcon } from "../../../assets/images/vote-icon.svg";
import { ButtonR } from "../../../components/form";
import { getShortTxt, locale, toShowDate } from "../../../components/helpers";
import { PAGES } from "../../../constants";

const ACTIVE_TYPE = 0;

export default function HistoryCard({
  id,
  imageId,
  actionDate = "",
  eventId,
  type,
  ...props
}) {
  const history = useHistory();

  useEffect(() => {}, []);

  function handleClick(params) {
    // window.stop();
    if (type === 0) history.push(`${PAGES.votesView}/${eventId}`);
    else history.push(`${PAGES.suggestionView}/${eventId}`);
  }

  function getIcon(params) {
    switch (type) {
      case 0:
        return <VoteIcon />;
        break;
      case 1:
        return <SuggestionIcon />;
        break;
      default:
        break;
    }
  }

  return (
    <div className="list_card" onClick={handleClick}>
      {getIcon()}
      <div className="list_card__info">
        <h3>{getShortTxt(locale(props, "title"), 140)}</h3>
        <div className="dates">
          <CalendarToday />
          <span>{toShowDate(actionDate, true)}</span>
        </div>
      </div>
      <div className="list_card__actions">
        <ButtonR
          variant={"outlined"}
          color="secondary"
          startIcon={<ArrowForwardOutlined />}
        ></ButtonR>
      </div>
    </div>
  );
}
