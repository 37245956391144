import { TextField } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectList from "../../../components/form/SelectList";
import CityNoveltyCard from "./CityNoveltyCard";

export default function CityNovelty({ type, list = [], year = null, setYear }) {
  const { t } = useTranslation();

  return (
    <div className="layout city_novelty">
      {year && (
        <div
          style={{
            width: 200,
            margin: "-20px 0 20px auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <DateRange style={{ marginRight: 15 }} />
          <SelectList
            label={t("choose_year")}
            name="year"
            list={[
              {
                id: 2020,
                name: 2020,
              },
              {
                id: 2021,
                name: 2021,
              },
              {
                id: 2022,
                name: 2022,
              },
            ]}
            value={year}
            onChange={(e) => {
              console.log("onChange", e);
              setYear(e.target.value);
            }}
          />
          {/* <KeyboardDatePicker
            views={["year"]}
            label="Выберите год"
            minDate={new Date("2020-01-01")}
            maxDate={new Date()}
            value={year}
            onChange={(newValue) => {
              setYear(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          /> */}
        </div>
      )}
      <div>
        {list.map((i, index) => (
          <CityNoveltyCard key={index} {...i} type={type} />
        ))}
      </div>
      {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
    </div>
  );
}
