import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { InputMask, InputText, ButtonR } from "../../../components/form";
import { ERROR, PAGES } from "../../../constants";
import { setUser } from "../../../redux/actions/appActions";
import { doLogin } from "./api";
import * as Yup from "yup";

const initialStateConst = {
  phone: "",
  password: "",
};

export default function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goBackUrl = "" } = useParams();

  console.log("urlParams", goBackUrl);

  const validate = Yup.object().shape({
    phone: Yup.string().required(t("required")),
    password: Yup.string().required(t("required")),
  });

  //Вход в систему
  function postData(values) {
    const params = {
      Login: values.phone,
      Password: values.password,
      Header: {
        PhoneNumber: values.phone,
        SystemCode: "IAM",
        Token: "",
        Version: 0,
      },
    };

    doLogin(params)
      .then((res) => {
        if (res.data.isSuccess) {
          dispatch(
            setUser({ phone: values.phone, token: res.data.data.token })
          );
          // history.push("/");
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("phone", values.phone);

          if (goBackUrl)
            window.location.href = "/#/" + goBackUrl.replace("_", "/");
          else window.location.href = "/";
        } else {
          enqueueSnackbar(t("incorrect_phone_pass"), {
            variant: ERROR,
          });
        }
      })
      .catch((err) => {});
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        postData(values);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit } = props;

        return (
          <form onSubmit={handleSubmit} className="mt-4">
            <InputMask
              name="phone"
              label={t("phone")}
              placeholder={t("phone_in")}
              value={values.phone}
              error={errors.phone}
              onChange={handleChange}
            />
            <InputText
              name="password"
              label={t("pass")}
              placeholder={t("pass_in")}
              type="password"
              value={values.password}
              error={errors.password}
              onChange={handleChange}
              maxLength={255}
            />
            <ButtonR
              variant="default"
              color="primary"
              onClick={() => history.push(PAGES.forgotPassword)}
            >
              {t("forgot_password")}
            </ButtonR>
            <div className="d-flex">
              <ButtonR type="submit" variant="contained" color="primary">
                {t("login_sys")}
              </ButtonR>
              <ButtonR
                variant="contained-light"
                color="secondary"
                onClick={() => history.push(PAGES.register)}
              >
                {t("register")}
              </ButtonR>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
