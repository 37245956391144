import { CalendarToday, ModeCommentOutlined } from "@material-ui/icons";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Thumb from "../../../components/design/list/Thumb";
import { ButtonR } from "../../../components/form";
import { getShortTxt, locale, toShowDate } from "../../../components/helpers";
import { PAGES } from "../../../constants";
import { getSuggestionImage, getVoteImage } from "../api";
import { useTranslation } from "react-i18next";

const ACTIVE_TYPE = 0;

export default function SuggestionCard({
  id,
  attachmentId,
  startDate = "",
  endDate = "",
  type,
  ...props
}) {
  const history = useHistory();

  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (attachmentId)
      getSuggestionImage(attachmentId)
        .then((res) => {
          setImage("data:image/png;base64," + res.data.data.content);
        })
        .catch((err) => {});
  }, []);

  function handleClick(params) {
    window.stop();
    history.push(`${PAGES.suggestionView}/${id}`);
  }

  return (
    <div className="list_card" onClick={handleClick}>
      <Thumb id={attachmentId} image={image} />
      <div className="list_card__info">
        <h3>{getShortTxt(locale(props, "title"), 140)}</h3>
        <div className="dates">
          <CalendarToday />
          <span>
            {toShowDate(startDate)} - {toShowDate(endDate)}
          </span>
        </div>
        <p>{locale(props, "description")}</p>
      </div>
      <div className="list_card__actions">
        <ButtonR
          variant={type === ACTIVE_TYPE ? "outlined" : "outlined-red"}
          color="primary"
          startIcon={<ModeCommentOutlined />}
        >
          {type === ACTIVE_TYPE ? t("talking") : t("finished")}
        </ButtonR>
        {/* {type === ACTIVE_TYPE && (
          <p>
            Вы получите за голосование: <span>2 балла</span>
          </p>
        )} */}
      </div>
    </div>
  );
}
