import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as ProfileArrow } from "../../assets/images/profile-arrow.svg";
import { ButtonR, InputMask, InputText } from "../../components/form";
import * as Yup from "yup";
import { getUserProfile, updateProfile } from "./api";
import { getRequestHeader } from "../../components/helpers";
import EmptyOrShow from "../../components/design/EmptyOrShow";
import { AccountCircle } from "@material-ui/icons";
import UploadFile from "../../components/form/UploadFile";
import ChangePassword from "./ChangePassword";
import { ERROR, PAGES, SUCCESS } from "../../constants";
import ProfilePhoto from "./ProfilePhoto";
import { useTranslation } from "react-i18next";

const initialStateConst = {
  phoneNumber: "",
  name: "",
  surname: "",
  middleName: "",
  email: "",
};

export default function ProfileSettings({ type }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const validate = Yup.object().shape({
    phoneNumber: Yup.string().required(t("required")),
    name: Yup.string().required(t("required")),
    surname: Yup.string().required(t("required")),
  });

  useEffect(() => {
    setLoading(true);
    getUserProfile(getRequestHeader())
      .then((res) => {
        if (res.data.data) setData(res.data.data);
        setLoading(false);

        //Redirect to login
        if (!res.data.isSuccess) history.push(PAGES.login);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [type]);

  function postData(values) {
    const params = {
      ...values,
      ...getRequestHeader(),
    };
    updateProfile(params)
      .then((res) => {
        if (res.data.isSuccess) {
          enqueueSnackbar(t("profile_update_success"), {
            variant: SUCCESS,
          });
        } else {
          enqueueSnackbar("Что-то пошло не так!", {
            variant: ERROR,
          });
        }
      })
      .catch((err) => {});
  }

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        postData(values);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit } = props;

        return (
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="profile_settings layout">
              <EmptyOrShow loading={loading}>
                {/* <h3>Фотография профиля:</h3>
                <div className="profile_settings__thumb">
                  <div className="image">
                    <AccountCircle />
                  </div>
                  <ProfileArrow />
                  <div>
                    <p>Ваша текущая фотография</p>
                    <UploadFile>
                      <ButtonR
                        variant="contained"
                        color="primary"
                        style={{ zIndex: "-1" }}
                      >
                        Загрузить новое фото
                      </ButtonR>
                    </UploadFile>
                    <span>Макс. размер: 1 мегабайт</span>
                  </div>
                </div> */}

                <ProfilePhoto />

                <h3>{t("personal")}</h3>
                <div className="profile_settings__personal_data">
                  <InputMask
                    name="phoneNumber"
                    label={t("phone")}
                    placeholder={t("phone_in")}
                    value={localStorage.getItem("phone")}
                    error={errors.phoneNumber}
                    onChange={handleChange}
                    disabled
                  />
                  <InputText
                    name="surname"
                    label={t("surname")}
                    placeholder={t("surname_in")}
                    value={values.surname}
                    error={errors.surname}
                    onChange={handleChange}
                    maxLength={255}
                  />
                  <InputText
                    name="name"
                    label={t("name")}
                    placeholder={t("name_in")}
                    value={values.name}
                    error={errors.name}
                    onChange={handleChange}
                    maxLength={255}
                  />
                  <InputText
                    name="fatherName"
                    label={t("fathername")}
                    placeholder={t("fathername_in")}
                    value={values.fatherName}
                    error={errors.fatherName}
                    onChange={handleChange}
                    maxLength={255}
                  />
                  <InputText
                    name="email"
                    label="Email"
                    placeholder={t("email_in")}
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                    maxLength={255}
                  />
                  <ButtonR type="submit" variant="contained" color="primary">
                    {t("save")}
                  </ButtonR>
                </div>
                <ChangePassword />
              </EmptyOrShow>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
