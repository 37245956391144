import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getCityProgramsList = () => {
  return instance(false)
    .get(`/CityProgram/GetAllCityPrograms`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getCityProgramsImage = (id) => {
  return instance(false)
    .get(`/CityProgram/GetCityProgramAttachmentAsFile?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getCityProgramsBody = (id) => {
  return instance(false)
    .get(`/CityProgram/v2/CityProgramBody?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
