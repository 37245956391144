import { Checkbox, withStyles } from "@material-ui/core";
import React from "react";

const GreenCheck = withStyles({
  root: {
    color: "#C0C9CE",
    "&$checked": {
      color: "#2BB472",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckR(props) {
  return <GreenCheck {...props} />;
}
