import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Template from "../../components/template";
import { LIGHT_TOP, TEXT } from "../../constants";
import { setPage } from "../../redux/actions/appActions";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function AboutProject() {
  const [value, setValue] = useState(0);
  const { t, i18n: { language } } = useTranslation();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️iAzamat",
    desc: `${t("inform")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("project") }],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, []);

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, [language]);
  
  return (
    <Template>
      <div className="about_project layout">
        <h3>{t("project")}</h3>
        <div className="quote">
        {t("proj_desc")}
          <p>{t("president")}</p>
        </div>
        <h3>{t("mobile")}</h3>
        <div className="mission">
          <h4>{t("mission")}</h4>
          <p>
            {t("realize")}
            <br />{t("realize_desc1")}
            <br />{t("realize_desc2")}
            <br />{t("realize_desc3")}
            <br />{t("realize_desc4")}
            <br />{t("realize_desc5")}
          </p>
        </div>
        <div className="mission">
          <h4>{t("aim")}</h4>
          <p>
          {t("aim_desc")}
          </p>
        </div>
        <div className="mission">
          <h4>{t("task")}</h4>
          <p>
            {t("task_desc")}
            <br />{t("task_desc1")}
            <br />{t("task_desc2")}
            <br />{t("task_desc3")}
            <br />{t("task_desc4")}
            <br />{t("task_desc5")}
          </p>
        </div>
        <div className="mission">
          <p>
          {t("desc")}{" "}
            <a href="mailto:iazamat@almatydc.kz" style={{ color: "#2bb472" }}>
              iazamat@almatydc.kz
            </a>
          </p>
        </div>
      </div>
    </Template>
  );
}
