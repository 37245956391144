import React, { useEffect, useState } from "react";
import SuggestionCard from "./SuggestionCard";

export default function Suggestions({ type, list = [] }) {
  return (
    <div className="layout">
      {list.map((i, index) => (
        <SuggestionCard key={index} {...i} type={type} />
      ))}
      {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
    </div>
  );
}
