import { Box } from "@material-ui/core";
import React from "react";

export default function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ display: value === index ? "block" : "none" }}>
        {children}
      </Box>
    </div>
  );
}
