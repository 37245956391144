import React from "react";

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { Divider } from "@material-ui/core";
import ForgotPasswordForm from "./form";
import DividerR from "../../../components/design/DividerR";
import AuthTemplate from "../template";
import { useTranslation } from "react-i18next";

//Забыли пароль
export default function ForgotPassword() {
  const { t } = useTranslation();

  return (
    <AuthTemplate>
      <h2>{t("forgot_pass")}</h2>
      <DividerR />

      <ForgotPasswordForm />
    </AuthTemplate>
  );
}
