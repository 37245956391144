import React, { useState } from "react";
import { AlertR } from "../../../components/design";
import { ButtonR, InputMask } from "../../../components/form";
import { getRequestHeader } from "../../../components/helpers";
import { checkSmsCode, getSmsCode } from "../forgot-password/api";
import { isRegistered } from "./api";
import Countdown from "react-countdown";
import { ERROR } from "../../../constants";
import { useTranslation } from "react-i18next";

//Первый шаг
export default function FirstStep({
  values,
  handleChange,
  handleNext,
  errors,
}) {
  const [send, setSend] = useState(false);
  const [reSend, setReSend] = useState(false);
  const [err, setErr] = useState(null);
  const { t } = useTranslation();
  //Проверка номера телефона
  async function handleCheckPhone(params) {
    if (values.phone.length !== 10) {
      setErr(t("incorrect_num"));
      return;
    }
    const params1 = {
      ...getRequestHeader(),
    };
    params1.header.phoneNumber = values.phone;
    const res = await isRegistered(params1);

    if (!res.data.data) {
      setErr(null);
      handleSendCode();
    } else setErr(t("user_is_exist"));
  }

  //Отправка смс
  async function handleSendCode() {
    if (values.phone.length === 10) {
      const params = {
        PhoneNumber: values.phone,
        SystemCode: "IAM",
      };

      getSmsCode(params)
        .then((res) => {
          if (res.data.isSuccess) {
            setReSend(Date.now());
            setSend(true);
          }
        })
        .catch((err) => {});
    } else setErr(t("incorrect_num"));
  }

  //Проверка смс
  function handleCheckCode() {
    setErr(null);
    const params = {
      SmsCode: values.code,
      PhoneNumber: values.phone,
      SystemCode: "IAM",
    };

    checkSmsCode(params)
      .then((res) => {
        if (res?.data?.isSuccess) {
          handleNext();
          setErr(null);
        } else {
          setErr(t("incorrect_code"));
        }
      })
      .catch((err) => {});
  }

  return (
    <>
      <InputMask
        name="phone"
        label={t("phone") + " *"}
        placeholder={t("phone_in")}
        value={values.phone}
        error={errors.phone}
        onChange={handleChange}
      />

      {send && (
        <>
          <InputMask
            name="code"
            label={t("sms_code") + " *"}
            placeholder={t("sms_code_in")}
            format="######"
            value={values.code}
            error={errors.code}
            onChange={handleChange}
          />
          <ButtonR
            variant="default"
            color="primary"
            disabled={reSend}
            onClick={handleSendCode}
          >
            {t("send_code_r")}{" "}
            {reSend && (
              <>
                {t("after")}{" "}
                <Countdown
                  date={reSend + 60000}
                  renderer={({ minutes, seconds }) => (
                    <span>
                      0{minutes}:{seconds}
                    </span>
                  )}
                  onComplete={() => setReSend(null)}
                />
                )
              </>
            )}
          </ButtonR>
        </>
      )}
      <AlertR type={ERROR} show={err} label={err} />
      <ButtonR
        onClick={send ? handleCheckCode : handleCheckPhone}
        variant="contained"
        color="primary"
      >
        {t("next")}
      </ButtonR>
    </>
  );
}
