import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import TabR from "../../../components/design/TabR";
import TabsR from "../../../components/design/TabsR";
import { a11yProps } from "../../../components/Tab";
import TabPanel from "../../../components/Tab/TabPanel";
import Template from "../../../components/template";
import { LIGHT_TOP, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getCityNoveltyList } from "../api";
import CityNovelty from "./CityNovelty";
import "./styles.scss";

export default function CityNoveltyList() {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "🔥" + `${t("news")}`,
    desc: t("last_city"),
    descType: TEXT,
    route: [{ name: `${t("main")}`, url: "/" }, { name: `${t("news")}` }],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getData(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    //Получение списка
    getData();
  }, []);

  useEffect(() => {
    if (value === 1) getData(true);
  }, [year]);

  const getData = (archive = false) => {
    let params = `archived=false&year=${year}`;
    if (archive) params = `archived=false&year=${year}`;
    setLoading(true);
    getCityNoveltyList(params)
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, [language]);

  return (
    <Template>
      <TabsR value={value} onChange={handleChange} className="layout">
        <TabR label={t("active")} {...a11yProps(0)} />
        <TabR label={t("archive_tab")} {...a11yProps(1)} />
      </TabsR>
      <Divider style={{ marginBottom: 25 }} />
      <TabPanel value={value} index={0}>
        <EmptyOrShow loading={loading}>
          <CityNovelty type={value} list={list} />
        </EmptyOrShow>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmptyOrShow loading={loading}>
          <CityNovelty type={value} list={list} year={year} setYear={setYear} />
        </EmptyOrShow>
      </TabPanel>
    </Template>
  );
}
