import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestHeader, locale } from "../../../components/helpers";
import { getBonusDescription, getBonusHistory } from "../../profile/api";
import BonusCard from "./BonusCard";
import { useTranslation } from "react-i18next";
import { CalendarToday, InfoOutlined } from "@material-ui/icons";
import { setShowModal } from "../../../redux/actions/appActions";
import { DEFAULT_MODAL } from "../../../constants";

export default function Bonus({ type, list = [] }) {
  const [userBonuses, setUserBonuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    //Получение истории
    getBonusHistory(getRequestHeader())
      .then((res) => {
        if (res.data.data) setUserBonuses(res.data.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  //Получение бала
  function getBonusTotal() {
    let total = 0;
    userBonuses.forEach((e) => {
      total = total + parseInt(e.amount);
    });
    return total;
  }

  function handleClick() {
    getBonusDescription().then((res) => {
      dispatch(
        setShowModal(true, DEFAULT_MODAL, {
          title: t("bonus_desc"),
          desc: locale(res.data.data, "description"),
        })
      );
    });
  }

  return (
    <div className="layout bonus">
      <div>
        <h3>{t("bonus_category")}</h3>
        {list.map((i, index) => (
          <BonusCard key={index} {...i} type={type} />
        ))}
        {/* <LoadMore onClick={handleLoadMore} withIcon/> */}
      </div>

      <div className={user.auth ? "total_bonus" : "total_bonus not_auth"}>
        {user.auth && (
          <>
            <h3>
              {getBonusTotal()} {t("calc")}
            </h3>
            <p>
              {t("bonus_amount")}{" "}
              <span onClick={handleClick}>
                <InfoOutlined />
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
}
