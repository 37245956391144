import { Button } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { InputMask, InputText, ButtonR } from "../../../components/form";
import { validate } from "./validate";

const initialStateConst = {
  phone: "",
  code: "",
};

export default function LoginForm() {
  function postData(values) {
    // dispatch(setTaskFormLoadingAction(true));
    // const params = {
    //   task_id: taskId,
    //   prorab_employee_id: returnData.brigadir,
    // };
    // acceptT01(params)
    //   .then((res) => {
    //     console.log("acceptT01 res", res);
    //     enqueueSnackbar("Задача успешно выполнен!", {
    //       variant: SUCCESS,
    //     });
    //     history.push(PAGES.tasksInProgress);
    //     dispatch(setTaskFormLoadingAction(false));
    //   })
    //   .catch((err) => {
    //     enqueueSnackbar(err.message, {
    //       variant: ERROR,
    //     });
    //     dispatch(setTaskFormLoadingAction(false));
    //   });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values) => {
        postData(values);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit } = props;

        return (
          <form onSubmit={handleSubmit} className="mt-4">
            <InputMask
              name="phone"
              label="Номер телефона:"
              placeholder="Введите номер телефона"
              value={values.phone}
              error={errors.phone}
              onChange={handleChange}
            />
            <InputMask
              name="code"
              label="Код из СМС:"
              placeholder="Введите 4-х значный код из СМС"
              format="####"
              value={values.code}
              error={errors.code}
              onChange={handleChange}
            />
            <ButtonR color="primary">
              Отправить код повторно (через 00:59)
            </ButtonR>
            <ButtonR variant="contained" color="primary">
              Продолжить
            </ButtonR>
          </form>
        );
      }}
    </Formik>
  );
}
