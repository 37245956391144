import React, { useEffect, useState } from "react";
import { getCityNoveltyImage } from "../api";

export default function CityNoveltyImage({ id }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    getCityNoveltyImage(id)
      .then((res) => {
        setImage("data:image/png;base64," + res.data.data.content);
      })
      .catch((err) => {});
  }, []);

  return <img alt="" src={image} />;
}
