import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PAGES } from "../../../constants";
import { getProfilePhoto } from "../../../pages/profile/api";
import { setUser } from "../../../redux/actions/appActions";
import Thumb from "../../design/list/Thumb";
import { getRequestHeader } from "../../helpers";
import { getProfile } from "./api";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronIcon } from "../../../assets/images/chevron-down.svg";

export default function UserThumb() {
  const [auth, setAuth] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const LINKS = [
    {
      title: t("setting"),
      url: PAGES.profile,
    },
    {
      title: t("bonus"),
      url: PAGES.profile + "/1",
    },
    {
      title: t("alert"),
      url: PAGES.profile + "/2",
    },
    {
      title: t("history"),
      url: PAGES.profile + "/3",
    },
    {
      title: t("exit"),
      url: PAGES.logout,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getProfile(getRequestHeader())
      .then((res) => {
        if (res.data.isSuccess) {
          dispatch(setUser({ ...res.data.data, auth: true }));
          getPhoto();
        } else {
          dispatch(setUser({ auth: false }));
        }
      })
      .catch((err) => {});
  };

  function getPhoto(params) {
    getProfilePhoto(getRequestHeader())
      .then((res) => {
        if (res.data.data)
          setImage("data:image/png;base64," + res.data.data.content);
      })
      .catch((err) => {});
  }

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (url) => {
    handleClose();
    if (url === PAGES.logout) {
      localStorage.removeItem("token");
      window.location.href = "/";
    } else history.push(url);
  };

  return (
    <div className="user_thumb">
      {user.auth ? (
        <>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="false"
            onClick={handleMenu}
            color="inherit"
          >
            <Thumb image={image} icon={<AccountCircle />} />
            <ChevronIcon style={{ border: "none" }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            className="menu_appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            {LINKS.map((i, index) => (
              <MenuItem key={index} onClick={() => handleClickMenu(i.url)}>
                {i.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <div className="links">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push(PAGES.login);
            }}
          >
            {t("log")}
          </a>
          <span>/</span>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push(PAGES.register);
            }}
          >
            {t("reg")}
          </a>
        </div>
      )}
    </div>
  );
}
