import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import { getRequestHeader } from "../../../components/helpers";
import Template from "../../../components/template";
import { LIGHT_TOP, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getCityProgramsList } from "../api";
import CityPrograms from "./CityPrograms";
import "./styles.scss";
import { useTranslation } from "react-i18next";


export default function CityProgramsList() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const { t, i18n: { language } } = useTranslation();
  const dispatch = useDispatch();
  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️🔥 " + `${t("city_programs")}`,
    desc: `${t("last_programs")}`,
    descType: TEXT,
    route: [
      { name: t("main"), url: "/" },
      { name: t("city_programs") },
    ],
  };
  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
    getCityProgramsList()
      .then((res) => {
        if (res.data.data) setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  
  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, [language]);
  return (
    <Template loading={loading}>
      <EmptyOrShow loading={loading}>
        <CityPrograms list={list} />
      </EmptyOrShow>
    </Template>
  );
}
