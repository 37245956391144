import React from "react";

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { Divider } from "@material-ui/core";
import RegisterForm from "./form";
import DividerR from "../../../components/design/DividerR";
import AuthTemplate from "../template";
import { useTranslation } from "react-i18next";

//Регистрация
export default function Register() {
  const { t } = useTranslation();
  return (
    <AuthTemplate>
      <h2>{t("register_sys")}</h2>
      <DividerR />
      <p>{t("fill_fields")}</p>
      <RegisterForm />
    </AuthTemplate>
  );
}
