import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Template from "../../components/template";
import { LIGHT_TOP, TEXT } from "../../constants";
import { setPage } from "../../redux/actions/appActions";
import "./styles.scss";

import { ReactComponent as Percent } from "../../assets/images/achievements/percent.svg";
import Turism1 from "../../assets/images/achievements/turism1.png";
import Turism2 from "../../assets/images/achievements/turism2.png";
import Turism3 from "../../assets/images/achievements/turism3.png";
import Turism4 from "../../assets/images/achievements/turism4.png";
import Turism5 from "../../assets/images/achievements/turism5.png";

import Business1 from "../../assets/images/achievements/business1.png";
import Business2 from "../../assets/images/achievements/business2.png";
import Business3 from "../../assets/images/achievements/business3.png";
import Business4 from "../../assets/images/achievements/business4.png";

import Buss1 from "../../assets/images/achievements/buss1.png";
import Buss2 from "../../assets/images/achievements/buss2.png";
import Buss3 from "../../assets/images/achievements/buss3.png";
import Buss4 from "../../assets/images/achievements/buss4.png";
import Buss5 from "../../assets/images/achievements/buss5.png";

import MSB from "../../assets/images/achievements/msb.svg";

import Cult1 from "../../assets/images/achievements/cult1.png";
import Cult2 from "../../assets/images/achievements/cult2.png";
import Cult3 from "../../assets/images/achievements/cult3.png";
import Cult4 from "../../assets/images/achievements/cult4.png";
import Cult5 from "../../assets/images/achievements/cult5.png";
import Cult6 from "../../assets/images/achievements/cult6.png";
import Cult7 from "../../assets/images/achievements/cult7.png";
import Cult8 from "../../assets/images/achievements/cult8.png";
import Cult9 from "../../assets/images/achievements/cult9.png";

import Sport1 from "../../assets/images/achievements/sport1.png";
import Sport2 from "../../assets/images/achievements/sport2.png";
import Sport3 from "../../assets/images/achievements/sport3.png";

import Sc1 from "../../assets/images/achievements/sc1.png";
import Sc2 from "../../assets/images/achievements/sc2.png";
import Sc3 from "../../assets/images/achievements/sc3.png";
import Sc4 from "../../assets/images/achievements/sc4.png";
import Sc5 from "../../assets/images/achievements/sc5.png";
import Sc6 from "../../assets/images/achievements/sc6.png";
import Sc7 from "../../assets/images/achievements/sc7.png";
import MapIcon from "../../assets/images/achievements/map.png";
import { useTranslation } from "react-i18next";

export default function CityAchievementsList() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: "️🎖 " + `${t("rating")}`,
    desc: `${t("main_rating")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("rating") }],
  };

  console.log("language", language, t("rating"));

  const BUSINESS = [
    {
      icon: Business1,
      percent: "91%",
      text: t("business1"),
    },
    {
      icon: Business2,
      percent: "30%",
      text: t("business2"),
    },
    {
      icon: Business3,
      percent: "24,7%",
      text: t("business3"),
    },
    {
      icon: Business4,
      percent: "53,5%",
      text: t("business4"),
    },
  ];
  const TURISM = [
    {
      icon: Turism1,
      text: t("tourism1"),
    },
    {
      icon: Turism2,
      text: t("tourism2"),
    },
    {
      icon: Turism3,
      text: t("tourism3"),
    },
    {
      icon: Turism4,
      text: t("tourism4"),
    },
    {
      icon: Turism5,
      text: t("tourism5"),
    },
  ];
  const CULTURE = [
    {
      icon: Cult1,
      number: "17",
      text: t("museum_count"),
    },
    {
      icon: Cult2,
      number: "31",
      text: t("lib_count"),
    },
    {
      icon: Cult3,
      number: "17",
      text: t("theatre_count"),
    },
    {
      icon: Cult4,
      number: "12",
      text: t("park_count"),
    },
    {
      icon: Cult5,
      number: "7",
      text: t("conc"),
    },
    {
      icon: Cult6,
      number: "2",
      text: t("zoo"),
    },
  ];
  const CULTURE2 = [
    {
      icon: Cult7,
      text: t("culture1"),
    },
    {
      icon: Cult8,
      text: t("culture2"),
    },
    {
      icon: Cult9,
      text: t("culture3"),
    },
  ];
  const SPORT = [
    {
      icon: Sport1,
      text: t("sport1"),
    },
    {
      icon: Sport2,
      text: t("sport2"),
    },
    {
      icon: Sport3,
      text: t("sport3"),
    },
  ];
  const SCIENCE = [
    {
      icon: Sc1,
      number: <img src={MapIcon} alt="" />,
      text: t("science2"),
    },
    {
      icon: Sc6,
      number: "37",
      text: t("science1"),
    },

    {
      icon: Sc7,
      number: "240 " + t("count"),
      text: t("science3"),
    },
  ];
  const SCIENCE2 = [
    {
      icon: Sc2,
      text: t("science4"),
    },
    {
      icon: Sc4,
      text: t("science5"),
    },
    {
      icon: Sc5,
      text: t("science6"),
    },
  ];

  useEffect(() => {
    setLoading(!loading);
    dispatch(setPage(PAGE_DATA));
  }, []);

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, [language]);

  return (
    <Template loading={false}>
      <div className="city_achievments">
        <div className="city_achievments__desc layout">
          <div className="city_achievments__desc__icon">
            <span>{t("almaty_is")}</span>
          </div>
          <p>{t("rating_desc")}</p>
        </div>
        <Block grey>
          <div className="d-flex">
            <div style={{ maxWidth: 540 }}>
              <h3>{t("economics")}</h3>
              <p>{t("eco_desc")}</p>
            </div>
            <div style={{ position: "relative" }}>
              <Percent />
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 100,
                  lineHeight: "93px",
                  color: "#5B5B59",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                25%
              </span>
            </div>
          </div>
        </Block>
        <h3 align="center" style={{ marginTop: 60, marginBottom: 0 }}>
          {t("almaty")}
        </h3>
        <div className="business">
          <Block>
            <div className="business__cards">
              {BUSINESS.map((i, index) => (
                <div>
                  <div className="business__card" key={index}>
                    <img src={i.icon} alt="" />
                    <p>{i.percent}</p>
                  </div>
                  <p>{i.text}</p>
                </div>
              ))}{" "}
            </div>
            <div className="business__vrp">
              <img src={MSB} alt="" />
              <p>
                {t("form")} <span className="green">52,2%</span> {t("wrp")}
              </p>
            </div>
          </Block>
          {/* <h4>{t("megapolis")}</h4>
            <h4 className="green">{t("msb_main")} </h4> */}
          <Block grey>
            <div className="business__cards2">
              <div>
                <img src={Buss1} alt="" />
                <p>
                  {t("msb1")}
                  <span>{t("msb2")}</span>
                  {t("msb3")}
                </p>
              </div>
              <div>
                <img src={Buss2} alt="" />
                <p>
                  <span>{t("msb_percent1")}</span>
                  {t("msb_percent2")}
                </p>
              </div>
            </div>
          </Block>
          <Block>
            <img
              src={Buss3}
              alt=""
              style={{ display: "block", margin: "auto" }}
            />
            <p align="center">{t("doing")}</p>
          </Block>
          <Block grey>
            <div className="business__cards3">
              <div>
                <p>{t("almaty_desc1")}</p>
                <img src={Buss4} alt="" />
              </div>
              <div
                className="flex-column"
                style={{ maxWidth: "50%", textAlign: "center" }}
              >
                <p style={{ fontSize: 20 }}>{t("almaty_desc2")}</p>
                <img src={Buss5} alt="" />
                <p style={{ fontSize: 20 }}>{t("almaty_desc3")}</p>
              </div>
            </div>
          </Block>
        </div>
        <Block>
          <h3 align="center">Туризм</h3>
          <p align="center">{t("almaty_tourism")}</p>
          <div className="turism">
            {TURISM.map((i, index) => (
              <div className="turism__card" key={index}>
                <div className="turism__card__icon">
                  <img src={i.icon} alt="" />{" "}
                </div>
                <p>{i.text}</p>
              </div>
            ))}
          </div>
        </Block>
        <Block grey>
          <h3 align="center">{t("culture")}</h3>
          <p align="center">{t("almaty_culture")}</p>
          <div className="culture">
            <div className="culture__cards">
              {CULTURE.map((i, index) => (
                <div>
                  <div className="culture__card" key={index}>
                    <div className="culture__card__icon">
                      <img src={i.icon} alt="" />
                    </div>
                    <p>{i.number}</p>
                  </div>
                  <p>{i.text}</p>
                </div>
              ))}
            </div>
            {/* <p align="center">{t("obj")}</p> */}
            <div className="culture__cards2">
              {CULTURE2.map((i, index) => (
                <div className="culture__card" key={index}>
                  <img src={i.icon} alt="" />
                  <p>{i.text}</p>
                </div>
              ))}
            </div>
          </div>
        </Block>
        <Block>
          <h3 align="center">Спорт</h3>
          <div className="sport">
            <div className="cards">
              {SPORT.map((i, index) => (
                <div className="card" key={index}>
                  <img src={i.icon} alt="" />
                  <p>{i.text}</p>
                </div>
              ))}
            </div>
          </div>
        </Block>
        <Block grey>
          <h3 align="center">{t("capital")}</h3>
          <p align="center">{t("almaty_capital")}</p>
          <div className="science">
            <div className="cards2">
              {SCIENCE.map((i, index) => (
                <div>
                  <div className="card" key={index}>
                    <img src={i.icon} alt="" />
                    <p>{i.number}</p>
                  </div>
                  <p>{i.text}</p>
                </div>
              ))}
            </div>
            <div className="science__ranks">
              <img src={Sc3} alt="" />
              <div>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>{t("ranks")}</p>
                <p>{t("ranks_top")}</p>
              </div>
            </div>
            <div className="cards science__last">
              {SCIENCE2.map((i, index) => (
                <div className="card" key={index}>
                  <img src={i.icon} alt="" />
                  <p>{i.text}</p>
                </div>
              ))}
            </div>
          </div>
        </Block>
      </div>
    </Template>
  );
}

function Block({ grey = null, children }) {
  return (
    <div className={`_block ${grey ? "_grey" : ""}`}>
      <div className="layout">{children}</div>
    </div>
  );
}
