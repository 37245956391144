import React from 'react';
import { LinearProgress, Card } from '@material-ui/core';

export default function EmptyOrShow({
  loading = true,
  show = true,
  width = '100%',
  // minHeight = "auto",
  children,
  inCard = false,
}) {
  function render(params) {
    return (
      <>
        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          <>{show ? <>{children}</> : null}</>
        )}
      </>
    );
  }

  if (inCard) {
    return <Card className="card-box-cnt mb-4">{render()}</Card>;
  } else {
    return <>{render()}</>;
  }
}
