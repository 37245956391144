import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  placeholder: {
    backgroundImage: "url(./images/logo.svg)",
    backgroundSize: "auto !important",
    backgroundPositionY: "center",
    backgroundPositionX: "center",
  },
  backgroundImage: {
    backgroundImage: ({ image }) => `url(${image})`,
  },
});

export default function Thumb({ image, icon = null }) {
  const { placeholder, backgroundImage } = useStyles({ image });

  if (icon && !image) return <>{icon}</>;

  return (
    <div className={`thumb ${image ? backgroundImage : placeholder}`}></div>
  );
}
