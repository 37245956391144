import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import { getRequestHeader, locale } from "../../../components/helpers";
import Template from "../../../components/template";
import { LIGHT_TOP, PAGES, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getBonusBody, getBonusList } from "../api";
import Bonus from "./Bonus";
import "./styles.scss";
import { useTranslation } from "react-i18next";

// const PAGE_DATA = {
//   topType: LIGHT_TOP,
//   title: "️🛍 Магазин поощрений",
//   desc: "Здесь вы можете потратить накопленные бонусы",
//   descType: TEXT,
//   route: [
//     { name: "Главная страница", url: "/" },
//     { name: "Мой профиль", url: PAGES.profile },
//     { name: "Магазин поощрений" },
//   ],
// };

export default function BonusView() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    //Получение полной инфы
    getBonusBody(id)
      .then((res) => {
        if (!isEmpty(res.data.data)) {
          let categoryName = locale(res.data.data[0].category, "title");

          let pageData = {
            topType: LIGHT_TOP,
            title: categoryName,
            desc: t("shop_desc"),
            descType: TEXT,
            route: [
              { name: t("main"), url: "/" },
              { name: t("myprofile"), url: PAGES.profile },
              { name: t("bonus"), url: PAGES.profile + "/1" },
              { name: t("shop"), url: PAGES.bonusList },
              { name: categoryName },
            ],
          };
          dispatch(setPage(pageData));
          if (res.data.data) {
            setList(
              res.data.data
              // .map((i) => {
              //   if (!isEmpty(i.types)) {
              //     i.types.forEach((el) => {
              //       i.inStockQuantity = i.inStockQuantity + el.inStockQuantity;
              //       i.takenQuantity = i.takenQuantity + el.takenQuantity;
              //     });
              //   }
              //   return i;
              // })
            );
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Template loading={loading}>
      <EmptyOrShow loading={loading}>
        <Bonus list={list} />
      </EmptyOrShow>
    </Template>
  );
}
