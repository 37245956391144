import { Box, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LoadMore } from "../../../components/design";
import EmptyOrShow from "../../../components/design/EmptyOrShow";
import TabR from "../../../components/design/TabR";
import TabsR from "../../../components/design/TabsR";
import { getRequestHeader } from "../../../components/helpers";
import { a11yProps } from "../../../components/Tab";
import TabPanel from "../../../components/Tab/TabPanel";
import Template from "../../../components/template";
import { LIGHT_TOP, PAGES, TEXT } from "../../../constants";
import { setPage } from "../../../redux/actions/appActions";
import { getVoteAttachmentAsFile, getVotesList } from "../api";
import Votes from "./Votes";
import { useTranslation } from "react-i18next";

import { ReactComponent as VoteIcon } from "../../../assets/images/section-icon-2.svg";

export default function VotesList() {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const PAGE_DATA = {
    topType: LIGHT_TOP,
    title: (
      <div
        className="d-flex align-items-center"
        style={{ marginBottom: "-40px" }}
      >
        <VoteIcon />
        <span style={{ marginLeft: 10 }}>{t("vote")}</span>
      </div>
    ),
    desc: `${t("votes")}`,
    descType: TEXT,
    route: [{ name: t("main"), url: "/" }, { name: t("vote") }],
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    dispatch(setPage(PAGE_DATA));
  }, [language]);

  function getData() {
    getVotesList(
      localStorage.getItem("token") ? getRequestHeader() : { header: null }
    )
      .then((res) => {
        setList(res.data.data || []);
        setLoading(false);
        //Redirect to login
        // if (!res.data.isSuccess) history.push(PAGES.login);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Template loading={loading}>
      <TabsR value={value} onChange={handleChange} className="layout">
        <TabR label={t("active")} {...a11yProps(0)} />
        <TabR label={t("finish")} {...a11yProps(1)} />
      </TabsR>
      <Divider style={{ marginBottom: 25 }} />
      <TabPanel value={value} index={0}>
        <EmptyOrShow loading={loading}>
          <Votes type={value} list={list.filter((i) => i.isActive === true)} />
        </EmptyOrShow>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmptyOrShow loading={loading}>
          <Votes type={value} list={list.filter((i) => i.isActive === false)} />
        </EmptyOrShow>
      </TabPanel>
    </Template>
  );
}
