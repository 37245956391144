import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ButtonR } from "../../../components/form";
import {
  getRequestHeader,
  getShortTxt,
  isEmpty,
  locale,
  toShowDate,
} from "../../../components/helpers";
import { ERROR, PAGES, SUCCESS } from "../../../constants";
import { setShowModal } from "../../../redux/actions/appActions";
import { getShopItem } from "../api";

//Модальное окно бонуса
export default function BonusModal({
  id,
  image,
  price = null,
  total,
  types = [],
  ...props
}) {
  const [checked, setChecked] = useState({});
  const [sendText, setSendText] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.app);
  const { t } = useTranslation();

  function handleChange(e) {
    let newObj = { ...checked, [e.target.name]: e.target.checked };
    setChecked(newObj);
  }

  function handleExchange() {
    setLoading(true);
    let params = {
      ShopItemId: id,
      ...getRequestHeader(),
    };
    if (price) {
      if (price > total) {
        enqueueSnackbar(t("bonus_ex_err1"), {
          variant: ERROR,
        });
        setLoading(false);
        return;
      }
      params.Quantity = 1;
    } else {
      if (isEmpty(checked)) {
        enqueueSnackbar(t("bonus_ex_err2"), {
          variant: ERROR,
        });
        setLoading(false);
        return;
      }
      let arr = [];
      Object.keys(checked).map((key, index) => {
        if (checked[key])
          arr.push({
            VariantId: parseInt(key),
            Quantity: 1,
          });
        params.Variants = arr;
      });
    }
    //Получение данных
    getShopItem(params)
      .then((res) => {
        if (res.data.isSuccess) {
          let text = `${res.data.data.code}. \n ${t(
            "bonus_promo"
          )} ${toShowDate(res.data.data.expireDate, true)}`;
          setSendText(text);
          setLoading(false);
          // enqueueSnackbar(text, {
          //   variant: SUCCESS,
          // });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else {
          enqueueSnackbar("Что-то пошло не так!", {
            variant: ERROR,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  if (sendText) return <SuccessModal text={sendText} />;

  return (
    <div className="bonus_modal">
      <h3 className="wrap">{getShortTxt(locale(props, "title"), 140)}</h3>
      <img src={image} alt="" />
      <div className="info">
        <h5>{t("bonus_amount_to_get")}:</h5>
        {price ? (
          <span>{price || 0}</span>
        ) : (
          <div className="d-flex flex-column">
            {types.map((i, index) => (
              <FormControl key={i.id} component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked["check" + i.id]}
                      onChange={handleChange}
                      name={i.id + ""}
                      color="primary"
                    />
                  }
                  disabled={i.price > total}
                  label={`${locale(i, "title")} - ${i.price}`}
                />
                {i.price > total && user.auth && (
                  <FormHelperText>{t("bonus_amount_low")}</FormHelperText>
                )}
              </FormControl>
            ))}
          </div>
        )}
      </div>
      <div className="info">
        <h5>{t("bonus_info")}:</h5>
        <p className="wrap">{locale(props, "description")}</p>
      </div>
      {user.auth && (
        <div className="info">
          <h5>{t("bonus_amount_got")}:</h5>
          <span>{total}</span>
        </div>
      )}
      <div className="d-flex justify-content-between">
        {user.auth && (
          <ButtonR
            variant="contained"
            color="primary"
            onClick={handleExchange}
            disabled={loading}
          >
            {t("bonus_exchange")}
          </ButtonR>
        )}
        <ButtonR
          onClick={() => dispatch(setShowModal(false))}
          variant="contained-light"
          color="secondary"
        >
          {t("close")}
        </ButtonR>
      </div>
    </div>
  );
}

function SuccessModal({ text }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="bonus_modal">
      <h3 className="wrap">{t("promocode")}:</h3>
      <h3>{text}</h3>
      <div className="d-flex justify-content-between">
        <ButtonR
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(PAGES.bonusList);
            dispatch(setShowModal(false));
          }}
        >
          Ok
        </ButtonR>
      </div>
    </div>
  );
}
