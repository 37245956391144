export const PAGES = {
  login: "/login",
  logout: "/logout",
  loginRegister: "/login-register",
  register: "/register",
  forgotPassword: "/forgot-password",
  votesList: "/votes",
  votesView: "/vote",
  suggestionList: "/suggestions",
  suggestionView: "/suggestion",
  cityNoveltyList: "/city-novelties",
  cityNoveltyView: "/city-novelty",
  historyList: "/histories",
  profile: "/profile",
  stopCovidList: "/stop-covid-articles",
  stopCovidView: "/stop-covid",
  emergencyCallList: "/emergency-call",

  newsList: "/all-news",
  newsView: "/news",
  about: "/about",
  aboutCity: "/about-city",
  cityPrograms: "/city-programs",
  cityProgramView: "/city-program",
  cityAchievements: "/city-achievements",
  bonusList: "/bonuses",
  bonusView: "/bonus",
};

export const API_URL = "https://test.api.iazamat.kz/api";

// export const API_URL = "https://demo.api.open-almaty.kz/api";

export const INFO = "info";
export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";

export const TEXT = "type:text";
export const DATE = "type:date";

export const LIGHT_TOP = "type:lightTop";
export const DARK_TOP = "type:darkTop";

export const VOTE_IMG_URL = API_URL + "/Vote/GetVoteAttachmentAsFile?id=";
export const CITY_PROGRAM_IMG_URL =
  API_URL + "/CityProgram/GetCityProgramAttachmentAsFile?id=";
export const STOP_COVID_IMG_URL =
  API_URL + "/StopCovid/GetStopCovidAttachment?id=";
// export const PROTECTED_PAGES = [PAGES.votesList,PAGES.votesView ]
// modals

export const IMG = "image";
export const SLIDE = "mimeType:slide";
export const VIDEO = "mimeType:video";

export const MIME_TYPES = [
  {
    type: IMG,
    data: "image",
  },
  {
    type: SLIDE,
    data: "presentation",
  },
  {
    type: SLIDE,
    data: "application",
  },
  {
    type: VIDEO,
    data: "mp4",
  },
];

export const DEFAULT_MODAL = "modal:default";
export const BONUS_MODAL = "modal:bonus";
export const VOTES_MODAL = "modal:votes";
export const VOTES_INFO_MODAL = "modal:votesInfo";
