import { ja } from "date-fns/locale";
import React from "react";
import { isEmpty } from "../../../components/helpers";
import Comment from "./Comment";
import CommentForm from "./form";
import { useTranslation } from "react-i18next";

export default function Comments({
  tab,
  list = [],
  isActive,
  getData,
  getRefreshData,
}) {
  const { t } = useTranslation();
  return (
    <div className="comments">
      {!isEmpty(list) && <h3>{tab === 0 ? t("comments") : t("asks")}</h3>}
      {list.map((i, index) => (
        <Comment
          key={i.id}
          tab={tab}
          isActive={isActive}
          getData={getData}
          getRefreshData={getRefreshData}
          {...i}
        />
      ))}
      {isActive && <CommentForm tab={tab} />}
    </div>
  );
}
