import { CheckCircleOutlineRounded } from "@material-ui/icons";
import { maxBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { locale, obj } from "../../../components/helpers";
import { getVoteShortResults } from "../api";
import AnswerLabel from "./AnswerLabel";

export default function Answers({
  questionId,
  stageNumber,
  answerTexts = [],
  isUserAnswer,
}) {
  const [data, setData] = useState([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    getVoteShortResults(id, stageNumber)
      .then((res) => {
        const currentQuestion = res.data.data?.questions?.filter(
          (i) => i.questionId === questionId
        )[0];
        let answersCount = 0;
        currentQuestion.answers.forEach((el) => {
          answersCount = answersCount + el.answerCount;
        });

        if (currentQuestion) {
          // let equalAnswers = 0;
          let answers = currentQuestion.answers.map((el, index) => {
            const attachments = locale(
              obj(answerTexts.filter((i) => i.id === el.answerId)[0]),
              "attachments"
            );
            // if (el.answerId)
            //   if (
            //     el.answerCount ===
            //     currentQuestion.answers[index + 1]?.answerCount
            //   ) {
            //     equalAnswers++;
            //   }

            return {
              ...el,
              percent: getPercent(el, answersCount),
              attachments,
            };
          });
          // console.log("answers", equalAnswers, answerTexts.length);
          // if (equalAnswers !== answerTexts.length - 1) {
          //   let maxAnswer = maxBy(answers, "percent");

          //   answers.forEach((el) => {
          //     if (el.answerId === maxAnswer.answerId)
          //       el.percent = el.percent + 1;
          //   });
          // }

          setData(answers);
        }
      })
      .catch((err) => {});
  }, []);

  const getPercent = (ans, allAnswers) => {
    if (data) {
      let percent = 0;
      if (allAnswers) percent = (ans.answerCount * 100) / allAnswers;
      else percent = 0;

      return parseFloat(percent.toFixed(1));
    } else return 0;
  };

  const LeftSide = ({ ans }) => {
    const count = parseInt(ans.answerCount);
    const lastDigit = parseInt(+count.toString().split("").pop());
    let voteTxt = "голоса";

    if (count > 20) {
      if (lastDigit === 0) voteTxt = "голосов";
      else if (lastDigit === 1) voteTxt = "голос";
      else if (lastDigit >= 2 && lastDigit <= 4) voteTxt = "голоса";
      else if (lastDigit >= 5) voteTxt = "голосов";
    } else {
      if (count === 0) voteTxt = "голосов";
      else if (count === 1) voteTxt = "голос";
      else if (count >= 2 && count <= 4) voteTxt = "голоса";
      else if (count >= 5 && count <= 20) voteTxt = "голосов";
    }

    if (language === "kz") voteTxt = t("vote_count");

    return (
      <>
        <span>{ans.percent} %</span>
        <span>
          {ans.answerCount} {voteTxt}
        </span>
      </>
    );
  };

  const getStyle = (ans) => {
    return {
      background: `linear-gradient(90deg, rgba(43, 180, 114, 0.2) 0%, rgba(43, 180, 114, 0.2) ${ans.percent}%, #f8faf9 ${ans.percent}%, rgba(248, 250, 249, 0) 100%)`,
    };
  };

  return (
    <div className="vote_questions__answers">
      {data.map((i, index) => (
        <React.Fragment key={i.answerId}>
          <div className={`d-flex align-items-center`} style={getStyle(i)}>
            <span>{<LeftSide ans={i} />}</span>
            <AnswerLabel text={locale(i, "answer")} files={i.attachments} />
            {isUserAnswer(questionId, i.answerId) && (
              <CheckCircleOutlineRounded style={{ marginLeft: "auto" }} />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
