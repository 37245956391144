import React from "react";

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { Divider } from "@material-ui/core";
import LoginForm from "./form";
import DividerR from "../../../components/design/DividerR";
import AuthTemplate from "../template";

export default function LoginRegister() {
  return (
    <AuthTemplate>
      <h2>Вход / Регистрация</h2>
      <DividerR />
      <p>Авторизация происходит с помощью номера мобильного телефона</p>
      <LoginForm />
    </AuthTemplate>
  );
}
