import React from "react";
import { IMG, SLIDE } from "../../constants";
import { getFileType } from "../helpers";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

export default function ImageOrDoc({ id, mimeType, name, baseUrl = "" }) {
  switch (getFileType(mimeType)) {
    case IMG:
      return (
        <SimpleReactLightbox>
          <SRLWrapper>
            <img alt="" src={baseUrl + id} />
          </SRLWrapper>
        </SimpleReactLightbox>
      );
    case SLIDE:
      return (
        <div className="link">
          <a href={baseUrl + id}>{name}</a>
        </div>
      );
    default:
      return <></>;
  }
}
