import React from "react";
import { getShortTxt, isEmpty } from "../helpers";
import { IconButton, Card } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeleteForever } from "@material-ui/icons";

export default function UploadFile({
  name,
  label = "",
  accept = "",
  onChange,
  file = null,
  children,
  withCard = false,
}) {
  function render(params) {
    return (
      <>
        <input
          style={{ width: 0, display: "none" }}
          name={name}
          id={"flat-button-file" + name}
          type="file"
          accept={accept}
          onChange={onChange}
        />
        <label
          className="upload-file d-flex flex-row align-items-center"
          htmlFor={"flat-button-file" + name}
          style={{ cursor: "pointer" }}
        >
          {children ? (
            <div>{children}</div>
          ) : (
            <FontAwesomeIcon
              style={{ width: 25, color: "#C4C4C4", marginRight: 10 }}
              icon={"paperclip"}
            />
          )}

          <span> {file ? getShortTxt(file.name, 35) : label}</span>
          {file || label ? (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                onChange({ target: { files: ["DELETE"] } });
              }}
            >
              <DeleteForever style={{ color: "#2BB472" }} />
            </IconButton>
          ) : null}
        </label>
      </>
    );
  }

  if (withCard) {
    return (
      <Card className="card-box-cnt p-2 mb-4" style={{ paddingBottom: 0 }}>
        {render()}
      </Card>
    );
  } else {
    return <div>{render()}</div>;
  }
}
