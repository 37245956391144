import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LANGS = {
  ru: "РУС",
  kz: "ҚАЗ",
};

export default function LangPicker() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [lang, setLang] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let l = localStorage.getItem("lang");
    if (l) {
      changeLanguage(l);
      setLang(l);
    } else {
      localStorage.setItem("lang", "ru");
      changeLanguage("ru");
      setLang("ru");
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (key) => {
    localStorage.setItem("lang", key);
    handleClose();
    setLang(key);
    // changeLanguage(key);
    window.location.reload();
  };

  return (
    <div className="lang-picker">
      <div className="lang_btn">
        <IconButton
          aria-controls="lang-appbar"
          aria-haspopup="false"
          onClick={handleMenu}
          color="inherit"
        >
          <Language />
          <span>{LANGS[lang]}</span>
        </IconButton>
        <div className="divider"></div>
      </div>
      <Menu
        id="lang-appbar"
        className="menu_appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {Object.keys(LANGS).map((i, index) => (
          <MenuItem key={index} onClick={() => handleClickMenu(i)}>
            {LANGS[i]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
