import { Tabs, withStyles } from "@material-ui/core";
import React from "react";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      // maxWidth: 110,
      width: "100%",
      backgroundColor: "#2BB472",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export default function TabsR(props) {
  return <StyledTabs {...props} />;
}
