import React from "react";
import { useTranslation } from "react-i18next";
import DividerR from "../../../components/design/DividerR";
import AuthTemplate from "../template";
import LoginForm from "./form";

//Войти
export default function Login() {
  const { t } = useTranslation();
  return (
    <AuthTemplate>
      <h2>{t("login")}</h2>
      <DividerR />
      <p>{t("login_desc")}</p>
      <LoginForm />
    </AuthTemplate>
  );
}
