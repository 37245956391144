import React, { useEffect, useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import ImageOrDoc from "../../../components/design/ImageOrDoc";
import { VOTE_IMG_URL } from "../../../constants";
import { getVoteImage } from "../api";

export default function AnswerLabel({ files = [], text }) {
  return (
    <div className="d-flex align-items-center wrap" style={{ width: "90%" }}>
      <div>
        {files.map((i, index) => (
          <ImageOrDoc key={i.id} {...i} baseUrl={VOTE_IMG_URL} />
        ))}
      </div>
      <span>{text}</span>
    </div>
  );
}
