import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import { PAGES } from "../constants";
import ForgotPassword from "../pages/auth/forgot-password";
import Login from "../pages/auth/login";
import LoginRegister from "../pages/auth/login-register";
import Register from "../pages/auth/register";
import SuggestionList from "../pages/suggestion/list";
import SuggestionView from "../pages/suggestion/view";
import MainPage from "../pages/main";
import VotesList from "../pages/votes/list";
import VotesView from "../pages/votes/view";
import CityNoveltyList from "../pages/city_novelty/list";
import CityNoveltyView from "../pages/city_novelty/view";
import HistoryList from "../pages/history/list";
import Profile from "../pages/profile";
import StopCovidList from "../pages/stop_covid/list";
import StopCovidView from "../pages/stop_covid/view";
import EmergencyCallList from "../pages/emergency_call/list";
import NewsView from "../pages/news/view";
import AboutProject from "../pages/about";
import AboutCity from "../pages/about_city";
import BonusList from "../pages/bonus/list";
import BonusView from "../pages/bonus/view";
import NewsList from "../pages/news/list";
import CityProgramsList from "../pages/city_programs/list";
import CityAchievementsList from "../pages/city_achievements/";
import ScrollToTop from "./ScrollToTop";
import CityProgramsView from "../pages/city_programs/view";

const baseUrl = "";

function MainRouter() {
  return (
    <HashRouter onUpdate={() => window.scrollTo(0, 0)}>
      <>
        {/* <ScrollToTop /> */}
        <Switch>
          <Route
            path={baseUrl + PAGES.login + "/:goBackUrl?"}
            component={Login}
          />
          <Route
            path={baseUrl + PAGES.loginRegister}
            component={LoginRegister}
          />
          <Route path={baseUrl + PAGES.register} component={Register} />
          <Route
            path={baseUrl + PAGES.forgotPassword}
            component={ForgotPassword}
          />
          <Route path={baseUrl + PAGES.votesList} component={VotesList} />
          <Route
            path={baseUrl + PAGES.votesView + "/:id"}
            component={VotesView}
          />
          <Route
            path={baseUrl + PAGES.suggestionList}
            component={SuggestionList}
          />
          <Route
            path={baseUrl + PAGES.suggestionView + "/:id"}
            component={SuggestionView}
          />
          <Route
            path={baseUrl + PAGES.cityNoveltyList}
            component={CityNoveltyList}
          />
          <Route
            path={baseUrl + PAGES.cityNoveltyView + "/:id"}
            component={CityNoveltyView}
          />
          <Route
            path={baseUrl + PAGES.profile + "/:tab?"}
            component={Profile}
          />
          <Route path={baseUrl + PAGES.historyList} component={HistoryList} />

          <Route
            path={baseUrl + PAGES.stopCovidList}
            component={StopCovidList}
          />
          <Route
            path={baseUrl + PAGES.stopCovidView + "/:id"}
            component={StopCovidView}
          />

          <Route
            path={baseUrl + PAGES.emergencyCallList}
            component={EmergencyCallList}
          />

          <Route path={baseUrl + PAGES.newsList} component={NewsList} />
          <Route
            path={baseUrl + PAGES.newsView + "/:id"}
            component={NewsView}
          />
          <Route path={baseUrl + PAGES.about} component={AboutProject} />
          <Route path={baseUrl + PAGES.aboutCity} component={AboutCity} />

          <Route path={baseUrl + PAGES.bonusList} component={BonusList} />
          <Route
            path={baseUrl + PAGES.bonusView + "/:id"}
            component={BonusView}
          />

          <Route
            path={baseUrl + PAGES.cityPrograms}
            component={CityProgramsList}
          />
          <Route
            path={baseUrl + PAGES.cityProgramView + "/:id"}
            component={CityProgramsView}
          />
          <Route
            path={baseUrl + PAGES.cityAchievements}
            component={CityAchievementsList}
          />
          <Route exact path={baseUrl + "/"} component={MainPage} />
        </Switch>
      </>
    </HashRouter>
  );
}

export default MainRouter;
