import React, { useEffect, useState } from "react";
import { getFileType } from "../../../components/helpers";
import { IMG, SLIDE, STOP_COVID_IMG_URL } from "../../../constants";
import { getStopCovidImage } from "../api";

export default function StopCovidImage({ id, name, mimeType }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(STOP_COVID_IMG_URL + id);
    // getStopCovidImage(id)
    //   .then((res) => {
    //     setImage("data:image/png;base64," + res.data.data.content);
    //   })
    //   .catch((err) => {});
  }, []);

  switch (getFileType(mimeType)) {
    case IMG:
      return <img alt="" src={image} />;
    case SLIDE:
      return (
        <div className="links">
          <a href={image}>{name}</a>
        </div>
      );

    default:
      return <></>;
      break;
  }
}
