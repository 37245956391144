import { Modal } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  BONUS_MODAL,
  DEFAULT_MODAL,
  VOTES_INFO_MODAL,
  VOTES_MODAL,
} from "../../constants";
import BonusModal from "../../pages/bonus/view/BonusModal";
import VotesInfoModal from "../../pages/votes/view/VotesInfoModal";
import VotesModal from "../../pages/votes/view/VotesModal";
import { setShowModal } from "../../redux/actions/appActions";
import { ButtonR } from "../form";

const MODALS = {
  [BONUS_MODAL]: BonusModal,
  [DEFAULT_MODAL]: DefaultModal,
  [VOTES_MODAL]: VotesModal,
  [VOTES_INFO_MODAL]: VotesInfoModal,
};

export default function ModalR() {
  const appState = useSelector((state) => state.app);
  let { show, type, data } = appState.modal;
  const dispatch = useDispatch();

  const ModalInner = MODALS[type] || MODALS[BONUS_MODAL];

  return (
    <Modal
      open={show}
      onClose={() => dispatch(setShowModal(false))}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal">
        <ModalInner {...data} />
      </div>
    </Modal>
  );
}

function DefaultModal({ title, desc }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="bonus_modal">
      <h3 className="wrap">{title}</h3>
      <div className="wrap"> {desc}</div>
      <div className="actions">
        <ButtonR
          variant="contained-light"
          color="secondary"
          onClick={() => dispatch(setShowModal(false))}
        >
          {t("close")}
        </ButtonR>
      </div>
    </div>
  );
}
