import { Tab, withStyles } from "@material-ui/core";
import React from "react";

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: 25,
    backgroundColor: "transparent",
    padding: 0,
    minWidth: 120,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function TabR(props) {
  return <StyledTab {...props} />;
}
