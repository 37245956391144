import { Button, withStyles } from "@material-ui/core";
import React from "react";

const ContainedButton = withStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "none",
    padding: "15px 25px",
    ["@media (max-width:780px)"]: {
      fontSize: 14,
      padding: "14px 15px",
    },
    border: "none",
    borderRadius: "4px",
    boxShadow: "none",
    marginRight: 10,
    color: "#fff",
    marginBottom: 20,
    "&:hover": {
      boxShadow: "none",
      borderRadius: "4px",
      border: "none",
    },
  },
}))(Button);

const ContainedLightButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F8FAF9",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "none",
    padding: "15px 25px",
    ["@media (max-width:780px)"]: {
      fontSize: 14,
      padding: "14px 15px",
    },
    border: "none",
    borderRadius: "4px",
    boxShadow: "none",
    marginRight: 10,
    color: "#161A17",
    marginBottom: 20,
    "&:hover": {
      boxShadow: "none",
      borderRadius: "4px",
      border: "none",
    },
  },
}))(Button);

const DefaultButton = withStyles((theme) => ({
  root: {
    display: "block",
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "20px",
    color: "#2BB472",
    textTransform: "none",
    padding: 0,
    marginBottom: 20,
    "&:hover": {
      padding: 0,
      marginBottom: 20,
      backgroundColor: "transparent",
    },
  },
}))(Button);

const OutlinedButton = withStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "20px",
    color: "#2BB472",
    border: "2px solid #2BB472",
    textTransform: "none",
    marginBottom: 20,
    padding: "15px 25px",
    ["@media (max-width:780px)"]: {
      fontSize: 14,
      padding: "14px 15px",
    },
    "&:hover": {
      marginBottom: 20,
      border: "2px solid #2BB472 !important",
      backgroundColor: "#2BB472 !important",
      color: "#fff",
      span: {
        color: "#fff !important",
      },
      svg: {
        fill: "#fff",
      },
    },
  },
}))(Button);

const OutlinedRedButton = withStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "20px",
    color: "#E84B55",
    border: "2px solid #E84B55",
    textTransform: "none",
    marginBottom: 20,
    padding: "15px 25px",
    ["@media (max-width:780px)"]: {
      fontSize: 14,
      padding: "14px 15px",
    },
    "&:hover": {
      marginBottom: 20,
      border: "2px solid #E84B55 !important",
      backgroundColor: "#E84B55 !important",
      color: "#fff",
      span: {
        color: "#fff !important",
      },
      svg: {
        fill: "#fff",
      },
    },
  },
}))(Button);

const styles = ({ theme }) => ({
  default: {},
  primary: {
    color: "#fff",
  },
  secondary: {
    color: "#161A17",
  },
});

function ButtonR(props) {
  const { classes = {}, className, variant, ...others } = props;

  switch (variant) {
    case "contained":
      return (
        <ContainedButton variant={variant} className={className} {...others} />
      );
    case "contained-light":
      return (
        <ContainedLightButton
          variant="contained"
          className={className}
          {...others}
        />
      );
    case "default":
      return (
        <DefaultButton variant={variant} className={className} {...others} />
      );
    case "outlined":
      return (
        <OutlinedButton variant={variant} className={className} {...others} />
      );
    case "outlined-red":
      return (
        <OutlinedRedButton
          variant="outlined"
          className={className}
          {...others}
        />
      );

    default:
      break;
  }
  return <ContainedButton {...others} />;
}

export default withStyles(styles)(ButtonR);
