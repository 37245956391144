import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getShortTxt } from "../../helpers";

export default function RouteLinks() {
  const { page = {} } = useSelector((state) => state.app);
  const { route } = page;

  const history = useHistory();

  function handleClick(url) {
    history.push(url);
  }

  return (
    <div className="route_links">
      {route.map(({ name, url }, index) => {
        if (index === route.length - 1) {
          return (
            <div key={index} className="child">
              {getShortTxt(name)}
            </div>
          );
        }
        return (
          <div onClick={() => handleClick(url)} key={index} className="parent">
            {getShortTxt(name)}
          </div>
        );
      })}
    </div>
  );
}
