import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as MenuWhite } from "../../../assets/images/menu-white.svg";
import { ReactComponent as MenuIcon } from "../../../assets/images/menu.svg";
import { setShowMenu } from "../../../redux/actions/appActions";

export default function Menu({ black }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleShowMenu(params) {
    dispatch(setShowMenu(true));
  }

  return (
    <div className="menu">
      <div className="menu__btn" onClick={handleShowMenu}>
        {black ? <MenuIcon /> : <MenuWhite />}
        <span>{t("menu")}</span>
      </div>
    </div>
  );
}
