import instance from "../../api/api";
import { handleError } from "../../api/helpers";

export const getSuggestionList = (params) => {
  return instance(false)
    .post(`/Suggestion/GetSuggestionTitles`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getSuggestionBody = (params) => {
  return instance(false)
    .post(`/Suggestion/GetSuggestionBody`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getSuggestionImage = (id) => {
  return instance(false)
    .post(`/Suggestion/GetSuggestionAttachment?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getSuggestionComments = (params) => {
  return instance(false)
    .post(`/Suggestion/GetSuggestionComments`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const sendComment = (params) => {
  return instance(false)
    .post(`/Suggestion/SubmitSuggestionComment`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const submitSuggestionRating = (params) => {
  return instance(false)
    .post(`/Suggestion/SubmitSuggestionRating`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const submitSuggestionCommentRating = (params) => {
  return instance(false)
    .post(`/Suggestion/SubmitSuggestionCommentRating`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
