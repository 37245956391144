import instance from "../../../api/api";
import { handleError } from "../../../api/helpers";

//Получение смс кода
export const getSmsCode = (params) => {
  return instance(false)
    .post(`/Sms/GetSmsCode`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

//Проверка смс кода
export const checkSmsCode = (params) => {
  return instance(false)
    .post(`/Sms/CheckSmsCode`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

//Изменение пароля
export const changePassword = (params) => {
  return instance(false)
    .post(`/Authentication/ChangePassword`, params)
    .then((res) => {
      return res;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
