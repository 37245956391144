import React, { useEffect, useState } from "react";
import { Chart } from "react-charts";

import Cov1 from "../../../assets/images/stop-covid/cov1.png";
import Cov2 from "../../../assets/images/stop-covid/cov2.png";
import Cov3 from "../../../assets/images/stop-covid/cov3.png";
import Cov4 from "../../../assets/images/stop-covid/cov4.png";
import {
  getStopCovidDailyStatistics,
  getStopCovidLocalStatistics,
} from "../api";
import { useTranslation } from "react-i18next";

export default function CovidStatistics() {
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { t } = useTranslation();
  const DATA = [
    {
      icon: Cov1,
      title: "410 850",
      desc: t("cov_inf"),
    },
    {
      icon: Cov2,
      title: "16 740",
      desc: t("cov_car"),
    },
    {
      icon: Cov3,
      title: "392 790",
      desc: t("cov_health"),
    },
    {
      icon: Cov4,
      title: "1 320",
      desc: t("cov_die"),
    },
  ];
  // const chartData = React.useMemo(
  //   () => [
  //     {
  //       label: "Series 1",
  //       data: [
  //         [0, 1],
  //         [1, 2],
  //         [2, 4],
  //         [3, 2],
  //         [4, 7],
  //       ],
  //     },
  //   ],
  //   []
  // );

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "time",
        position: "bottom",
        show: true,
        format: (d) => formatDate(d),
      },
      { type: "linear", position: "left", show: true },
    ],
    []
  );

  function formatDate(d) {
    const replaceData = [
      { key: "April", value: "Апрель" },
      { key: "Tue", value: "Вт" },
      { key: "Wed", value: "Ср" },
      { key: "Thu", value: "Чт" },
      { key: "Fri", value: "Пт" },
      { key: "Сб", value: "Вс" },
      { key: "Sun", value: "Пон" },
    ];
    let exist = replaceData.filter((i) => i.key === d)[0];
    if (exist) return exist.value;
    return d.replace(/^\D+/g, "");
  }

  useEffect(() => {
    getData();
  }, []);

  function getData(params) {
    getStopCovidDailyStatistics()
      .then((res) => {
        let dead = 0,
          infected = 0,
          cured = 0,
          charts = [];

        res.data.forEach((el) => {
          dead = dead + el.snapshot.dead;
          infected = infected + el.snapshot.infected;
          cured = cured + el.snapshot.cured;
          charts.push([new Date(el.timestamp).getTime(), el.snapshot.infected]);
        });
        setChartData([
          {
            label: t("cov_inf"),
            data: charts.slice(charts.length - 20, charts.length - 1),
          },
        ]);
        return [
          {
            icon: Cov3,
            title: cured,
            desc: t("cov_health"),
          },
          {
            icon: Cov4,
            title: dead,
            desc: t("cov_die"),
          },
        ];
      })
      .then((arr) => {
        getStopCovidLocalStatistics().then((res) => {
          let infected = res.data[res.data.length - 1].snapshot.infected,
            quarantined =
              res.data[res.data.length - 1].snapshot.quarantined.home +
              res.data[res.data.length - 1].snapshot.quarantined.stationary;
          // res.data.forEach((el) => {
          //   quarantined =
          //     quarantined +
          //     el.snapshot.quarantined.home +
          //     el.snapshot.quarantined.stationary;
          // });
          setData([
            {
              icon: Cov1,
              title: infected,
              desc: t("cov_inf"),
            },
            {
              icon: Cov2,
              title: quarantined,
              desc: t("cov_car"),
            },
            ...arr,
          ]);
        });
      });
  }

  return (
    <div className="covid_statistics layout">
      <div>
        <h3>COVID-19:</h3>
        <div className="left">
          {data.map((i, index) => (
            <IconText key={index} {...i} />
          ))}
        </div>
      </div>
      <div>
        <Chart data={chartData} axes={axes} />
      </div>
    </div>
  );
}

function IconText({ icon, title, desc }) {
  return (
    <div className="statistic">
      <img src={icon} alt="" />
      <p>{title}</p>
      <span>{desc}</span>
    </div>
  );
}
