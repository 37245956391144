import { ArrowForwardOutlined, Phone } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ButtonR } from "../../../components/form";
import { PAGES } from "../../../constants";

const ACTIVE_TYPE = 0;

export default function EmergencyCallCard({
  id,
  icon,
  title = "»",
  number = "",
  number2 = "",
  createdDate = "",
  type,
}) {
  const history = useHistory();

  const [image, setImage] = useState(null);

  useEffect(() => {
    // if (imageId)
    //   getEmergencyCallImage(imageId)
    //     .then((res) => {
    //       setImage("data:image/png;base64," + res.data.data.content);
    //     })
    //     .catch((err) => {});
  }, []);

  function handleClick(params) {
    window.stop();
    history.push(`${PAGES.EmergencyCallView}/${id}`);
  }

  function handleClickBtn() {
    window.location.href = "tel:" + number;
  }

  return (
    <div className="list_card">
      <div className="icon">{icon}</div>
      <div className="list_card__info">
        <h3>{title}</h3>
        <div className="desc">
          {number}
          {number2 ? ", " + number2 : null}
        </div>
      </div>
      <div className="list_card__actions">
        <a href={"tel:" + number}>
          <Phone />
        </a>
        {/* <ButtonR
          href={"tel:" + number}
          variant="outlined"
          color="secondary"
          startIcon={<Phone />}
          // onClick={handleClickBtn}
        ></ButtonR> */}
      </div>
    </div>
  );
}
