import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { ButtonR, InputText } from "../../components/form";
import { getRequestHeader } from "../../components/helpers";
import { ERROR, SUCCESS } from "../../constants";
import { updatePassword } from "./api";

const initialStateConst = {
  oldPassword: "",
  password: "",
  passwordRepeat: "",
};

export default function ßChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validate = Yup.object().shape({
    oldPassword: Yup.string().required(t("required")),
    password: Yup.string().required(t("required")),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref("password"), null], t("pass_match"))
      .required(t("required")),
  });

  function postData(values, resetForm) {
    const params = {
      Login: values.phone,
      OldPassword: values.oldPassword,
      NewPassword: values.password,
      ...getRequestHeader(),
    };

    updatePassword(params)
      .then((res) => {
        if (res.data.isSuccess) {
          resetForm();
          enqueueSnackbar(t("change_pass_success"), {
            variant: SUCCESS,
          });
        } else {
          const errors = {
            101: t("user_not_found"),
            102: t("old_pass_incorrect"),
            103: t("new_pass_incorrect"),
          };
          enqueueSnackbar(errors[res?.data?.code], {
            variant: ERROR,
          });
        }
      })
      .catch((err) => {});
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      validateOnChange={false}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        postData(values, resetForm);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit } = props;

        return (
          <div className="mt-4">
            <h3>{t("pass_change")}</h3>
            <div className="profile_settings__change_password">
              <InputText
                name="oldPassword"
                label={t("current_pass")}
                placeholder={t("current_pass")}
                type="password"
                value={values.oldPassword}
                error={errors.oldPassword}
                onChange={handleChange}
              />
              <InputText
                name="password"
                label={t("new_pass2")}
                placeholder={t("new_pass2")}
                type="password"
                value={values.password}
                error={errors.password}
                onChange={handleChange}
              />
              <InputText
                name="passwordRepeat"
                label={t("new_pass_repeat")}
                placeholder={t("new_pass_repeat")}
                type="password"
                value={values.passwordRepeat}
                error={errors.passwordRepeat}
                onChange={handleChange}
              />
              <ButtonR
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                {t("save")}
              </ButtonR>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
