import React from "react";
import { TextField, FormControl } from "@material-ui/core";

export default function InputText({
  name = "",
  type = "text",
  label = "",
  placeholder = "",
  value = "",
  onChange,
  readOnly = false,
  className = "m-form",
  error = "",
  inputComponent = null,
  variant = "outlined",
  shrink = false,
  fieldStyle = {},
  multiline = null,
  maxLength,
}) {
  let inputProps = inputComponent ? { ...inputComponent } : {},
    inputLabelProps = shrink ? { shrink } : {};

  return (
    <div className="input_text">
      <p>{label}</p>
      <TextField
        fullWidth
        id={name}
        name={name}
        type={type}
        className={className}
        // label={label}
        value={value}
        onChange={onChange}
        variant={variant}
        placeholder={placeholder}
        inputProps={{
          readOnly: readOnly,
          maxLength: maxLength,
          ...inputProps,
        }}
        InputLabelProps={{
          ...inputLabelProps,
        }}
        multiline={multiline}
        helperText={error}
        error={error ? true : false}
      />
    </div>
  );
}
