import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import { ButtonR, InputText } from "../../../components/form";
import { getRequestHeader } from "../../../components/helpers";
import { ERROR, PAGES, SUCCESS } from "../../../constants";
import { sendComment } from "../api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const initialStateConst = {
  comment: "",
};

export default function CommentForm({
  tab = 0,
  parentCommentId = null,
  handleSend = () => {},
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useSelector((state) => state.app);
  const history = useHistory();

  const validate = Yup.object().shape({
    comment: Yup.string()
      .test("len", t("symbol_limit"), (val) => {
        if (val) return val.length < 3000;
        else return true;
      })
      .required(t("required")),
  });

  const STRINGS = {
    tab1: {
      title: t("your_comm"),
      placeholder: t("write_comm"),
      btn: t("send_comm"),
      success: t("comm_sended"),
    },
    tab2: {
      title: t("your_ask"),
      placeholder: t("write_ask"),
      btn: t("request"),
      success: t("ask_sended"),
    },
    reply: {
      title: t("answer"),
      placeholder: t("answer_write"),
      btn: t("send"),
      success: t("answer_sended"),
    },
  };

  function postData(values, resetForm, setSubmitting) {
    if (!user?.auth) {
      history.push(PAGES.login + "/suggestion_" + id);
      return;
    }

    const params = {
      suggestionId: id,
      type: parseInt(tab) + 1,
      comment: values.comment,
      parentCommentId: parentCommentId,
      ...getRequestHeader(),
    };

    sendComment(params)
      .then((res) => {
        if (res.data.isSuccess) {
          enqueueSnackbar(getStrings().success, {
            variant: SUCCESS,
          });
          resetForm();
          setSubmitting(false);
          handleSend();
          // window.location.reload();
        } else {
          enqueueSnackbar("Превышен допустимый лимит символов!", {
            variant: ERROR,
          });
        }
      })
      .catch((err) => {})
      .finally(() => setSubmitting(false));
  }

  function getStrings() {
    if (parentCommentId === null) {
      if (tab === 0) return STRINGS.tab1;
      else return STRINGS.tab2;
    } else return STRINGS.reply;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialStateConst}
      validationSchema={validate}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        postData(values, resetForm, setSubmitting);
      }}
    >
      {(props) => {
        let { values, errors, handleChange, handleSubmit, isSubmitting } =
          props;
        return (
          <form
            onSubmit={handleSubmit}
            className="comments-form mt-4"
            id={"comments-form-" + tab}
          >
            <h3>{getStrings().title} </h3>
            <div>
              <InputText
                name="comment"
                placeholder={getStrings().placeholder}
                value={values.comment}
                error={errors.comment}
                onChange={handleChange}
                maxLength={1000}
                multiline
              />
              <div className="txt_count">{values.comment.length} / 1000</div>
            </div>

            <ButtonR
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {getStrings().btn}
            </ButtonR>
          </form>
        );
      }}
    </Formik>
  );
}
