import React from "react";
import { useHistory } from "react-router";
import { ReactComponent as LogoWhite } from "../../../assets/images/logo-white.svg";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { PAGES } from "../../../constants";
import LangPicker from "./LangPicker";
import Menu from "./Menu";
import UserThumb from "./UserThumb";

export default function Header({ black = null }) {
  const history = useHistory();
  return (
    <div className="header">
      <Menu black={black} />
      <div className="logo" onClick={() => history.push("/")}>
        {black ? <Logo /> : <LogoWhite />}
      </div>
      <div className="d-flex">
        <LangPicker />
        {/* <Search /> */}
        <UserThumb />
      </div>
    </div>
  );
}
